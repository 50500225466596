import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Modal, Typography } from '@aq_mobile/ui-kit';

import { NavigationBlockerProps } from './NavigationBlocker.types';

/**
 * Компонент, блокирующий переход пользователя на другой URL
 * если выставлен флаг shouldBlock.
 *
 * Переход в рамках приложения обрабатывается, отображением модального окна.
 * Переход с помощью функционала браузера (F5 или смена URL), обрабатывается
 * стандартным функционалом браузера.
 */
function NavigationBlocker({
  shouldBlock = false,
  title,
  message,
}: NavigationBlockerProps) {
  const { t } = useTranslation();
  const titleLabel = title ?? t('components.NavigationBlocker.title');
  const messageLabel = message ?? t('components.NavigationBlocker.message');
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return shouldBlock && currentLocation.pathname !== nextLocation.pathname;
  });

  /** Обработка ухода со страницы, во время редактирования. */
  useEffect(() => {
    if (!shouldBlock) {
      return;
    }

    const warnFunction = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = t('components.NavigationBlocker.changesMayBeLost');
    };

    window.addEventListener('beforeunload', warnFunction);

    return () => {
      window.removeEventListener('beforeunload', warnFunction);
    };
  }, [shouldBlock, t]);

  return (
    <Modal
      open={blocker.state === 'blocked'}
      onOk={blocker.proceed}
      onCancel={blocker.reset}
      title={titleLabel}
    >
      <Typography.TextS>{messageLabel}</Typography.TextS>
    </Modal>
  );
}

export default NavigationBlocker;
