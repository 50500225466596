import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Chip,
  ContentCard,
  Dropdown,
  DropDownItemStringKey,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import { PlusIcon, TrashIcon } from '@aq_mobile/ui-kit/icons';
import { dateToLocaleString, strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Popconfirm, Row, Space, Spin, TableColumnsType } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { i18n, TFunction } from 'i18next';

import usePermission from '@/features/permissions/hooks/usePermission';
import {
  APP_STORE_SORT_COLUMN_SETTING,
  StoreAPK,
  useStoreAPKEdit,
  useStoreAPKs,
} from '@/features/storeApk';
import getTableLocale from '@/utils/getTableLocale';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

function getColumnsTemplate(
  t: TFunction<'translation', undefined>,
  i18n: i18n,
): TableColumnsType<StoreAPK> {
  return [
    {
      key: 'release_name',
      title: t('components.StoreUpdates.releaseVersion'),
      dataIndex: 'release_name',
      defaultSortOrder: getSortColumnSettings(
        APP_STORE_SORT_COLUMN_SETTING,
        'release_name',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.release_name.localeCompare(b.release_name),
    },
    {
      key: 'release_date',
      title: t('components.StoreUpdates.releaseAddedDate'),
      dataIndex: 'release_date',
      defaultSortOrder: getSortColumnSettings(
        APP_STORE_SORT_COLUMN_SETTING,
        'release_date',
        'descend',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: StoreAPK, b: StoreAPK) => {
        const dateA = Number(a.release_date);
        const dateB = Number(b.release_date);

        return dateA - dateB;
      },
      render: (dateStr: string) => {
        return dateToLocaleString(strftimeToJsDate(dateStr), i18n.language);
      },
    },
    {
      key: 'status',
      title: t('components.StoreUpdates.releaseStatus'),
      dataIndex: 'approved',
      render: (approved: boolean) =>
        approved ? (
          <Chip type="success">
            {t('components.StoreUpdates.statusApproved')}
          </Chip>
        ) : (
          <Chip type="error">
            {t('components.StoreUpdates.statusDeclined')}
          </Chip>
        ),
      onFilter: (value, record) => {
        if (value === 'all') {
          return true;
        }

        return record.approved === value;
      },
      ellipsis: true,
    },
  ];
}

const PAGE_SIZE = 8;

/**
 * Страница списка релизов магазина приложений.
 */
export default function StoreUpdates() {
  const { t, i18n } = useTranslation();
  const [currentTablePage, setCurrentAppTablePage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { isStoreAPKsLoading, storeAPKs } = useStoreAPKs();
  const { deleteStoreAPK, isStoreAPKDeleting } = useStoreAPKEdit();

  const navigate = useNavigate();
  const isDeleteAllowed = usePermission('storeDelete');
  const isAddAllowed = usePermission('storeAdd');

  const [currentStatusFilter, setCurrentStatusFilter] = useState<{
    key: string;
    label: string;
  }>({
    key: 'all',
    label: t('components.StoreUpdates.allStatuses'),
  });

  const approvedFilterItems = useMemo(
    () => [
      {
        key: 'approved',
        label: t('components.StoreUpdates.statusApproved'),
      },
      {
        key: 'disapproved',
        label: t('components.StoreUpdates.statusDeclined'),
      },
      {
        key: 'all',
        label: t('components.StoreUpdates.allStatuses'),
      },
    ],
    [t],
  );

  const columnsTemplate = useMemo(() => getColumnsTemplate(t, i18n), [t, i18n]);

  const rowSelection = useMemo(() => {
    // Если rowSelection = undefined скрывается возможность удалять записи.
    if (!isDeleteAllowed) {
      return undefined;
    }

    return {
      selectedRowKes: selectedRowKeys,
      onChange: setSelectedRowKeys,
    };
  }, [isDeleteAllowed, selectedRowKeys]);

  const handleDelete = useCallback(() => {
    selectedRowKeys.forEach((id) => {
      deleteStoreAPK(id.toString());
    });

    setSelectedRowKeys([]);
  }, [deleteStoreAPK, selectedRowKeys]);

  const handleGoToApk = useCallback(
    ({ id }: StoreAPK) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  const handleNewApk = useCallback(() => {
    navigate('new');
  }, [navigate]);

  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<StoreAPK> | SorterResult<StoreAPK>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      if (
        ['release_name', 'release_date'].includes(sorter.columnKey.toString())
      ) {
        setSortColumnSettings(
          APP_STORE_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  const tableData = useMemo(() => {
    if (!storeAPKs.length) {
      return [];
    }

    const filteredData = storeAPKs.filter((row) => {
      switch (currentStatusFilter.key) {
        case 'approved':
          return row.approved;
        case 'disapproved':
          return !row.approved;
        default:
          return true;
      }
    });
    return filteredData;
  }, [storeAPKs, currentStatusFilter.key]);

  const isPageInProgress = isStoreAPKsLoading || isStoreAPKDeleting;

  return (
    <ContentCard>
      <ContentCard.Header
        title={t('components.StoreUpdates.title')}
        buttons={
          isAddAllowed ? (
            <Button type="primary" icon={<PlusIcon />} onClick={handleNewApk}>
              {t('components.StoreUpdates.addUpdate')}
            </Button>
          ) : undefined
        }
      />

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col>
            {Boolean(selectedRowKeys.length) && (
              <Popconfirm
                title={t('components.StoreUpdates.confirmDeletion')}
                description={
                  selectedRowKeys.length > 1
                    ? t('components.StoreUpdates.areYouSureMultipleDelete')
                    : t('components.StoreUpdates.areYouSureSingleDelete')
                }
                okText={t('components.StoreUpdates.remove')}
                cancelText={t('components.StoreUpdates.cancel')}
                onConfirm={handleDelete}
              >
                <Button
                  icon={<TrashIcon />}
                  type="primary"
                  title={t('components.StoreUpdates.removeSelected')}
                />
              </Popconfirm>
            )}
          </Col>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              <Typography.TextS>
                {t('components.StoreUpdates.statusFilter')}
              </Typography.TextS>
              <Dropdown<DropDownItemStringKey>
                items={approvedFilterItems}
                selectedText={currentStatusFilter.label}
                onSelect={setCurrentStatusFilter}
              />
            </Space>
          </Col>
          <Col>
            <PaginationTotal
              currentPage={currentTablePage}
              pageSize={PAGE_SIZE}
              total={tableData.length || 0}
            />
          </Col>
        </Row>

        <Spin spinning={isPageInProgress}>
          <TableStyled
            rowSelection={rowSelection}
            dataSource={tableData}
            columns={columnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentAppTablePage(page);
              },
            }}
            onChange={handleTableChange}
            locale={getTableLocale(i18n.language)}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  handleGoToApk(record);
                },
              };
            }}
          />
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
