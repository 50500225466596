import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Button, NotificationsDropdown, Typography } from '@aq_mobile/ui-kit';
import {
  ChevronBackwardIcon,
  ChevronForwardIcon,
  ExitIcon,
  GearIcon,
} from '@aq_mobile/ui-kit/icons';
import { BreakPoints } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Avatar, Dropdown, Flex, Layout } from 'antd';

import { MenuLogo } from '@/components';
import { useAppSelector } from '@/features/hooks';
import {
  useNotificationEdit,
  useNotifications,
} from '@/features/notifications';
import { mapNotificationToMessage } from '@/features/notifications/notifications.utils';
import { ReleaseStatus } from '@/features/release';
import { userMailSelector } from '@/features/user/user.selectors';

const ButtonCollapseStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})<{ collapsed?: boolean }>`
  &.ant-btn.ant-btn-lg {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.collapsed ? '30px' : '40px')};
    font-size: 20px;
    transform: translateX(${(props) => (props.collapsed ? '0' : '-40px')});
    border-top-right-radius: ${(props) => (props.collapsed ? '8px' : '0')};
    border-bottom-right-radius: ${(props) => (props.collapsed ? '8px' : '0')};
    border-top-left-radius: ${(props) => (props.collapsed ? '0' : '8px')};
    border-bottom-left-radius: ${(props) => (props.collapsed ? '0' : '8px')};
  }
`;

const ButtonUserStyled = styled(Button)`
  &.ant-btn {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  & .ant-typography {
    display: none;
  }

  @media ${BreakPoints.sm} {
    & .ant-typography {
      display: inline-block;
    }
  }
`;

const UserNameStyled = styled(Typography.TextS)`
  display: none !important;

  @media ${BreakPoints.md} {
    display: inline-block !important;
  }
`;

const LogoStyled = styled(MenuLogo)`
  flex: 1;
  font-size: 14px !important;

  @media ${BreakPoints.xs} {
    font-size: 16px !important;
  }
`;

const HeaderStyled = styled(Layout.Header)`
  border-bottom-right-radius: 16px;
  padding-right: 12px !important;
  gap: 8px;
  display: flex;
  align-items: center;

  @media ${BreakPoints.xs} {
    padding-right: 24px !important;
    gap: 16px;
  }
`;

/** Компонент заголовка приложения */
function Header({
  isSiderCollapsed = false,
  onCollapseClick,
}: {
  isSiderCollapsed: boolean;
  onCollapseClick?: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userMail =
    useAppSelector(userMailSelector) || t('components.Header.defaultUserName');
  const { notifications } = useNotifications();
  const { readNotification, readNotifications } = useNotificationEdit();

  const userMenu = useMemo(
    () => [
      {
        key: 'settings',
        label: (
          <Flex align="center" gap={8}>
            <GearIcon style={{ fontSize: 20 }} />
            {t('components.Header.settings')}
          </Flex>
        ),
      },
      {
        key: 'logout',
        label: (
          <Flex align="center" gap={8}>
            <ExitIcon style={{ fontSize: 20 }} />
            {t('components.Header.exit')}
          </Flex>
        ),
      },
    ],
    [t],
  );

  const notificationItems = useMemo(
    () => mapNotificationToMessage(notifications, t),
    [notifications, t],
  );

  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      switch (key) {
        case 'settings':
          navigate('/users/profile', { state: { login: userMail } });
          return;
        case 'logout':
        default:
          navigate('/logout');
          return;
      }
    },
    [navigate, userMail],
  );

  const handleNotificationRead = useCallback(
    (id: number) => {
      readNotification(id);
    },
    [readNotification],
  );

  const handleAllNotificationsRead = useCallback(() => {
    const notificationIds = notifications.map(
      (notification) => notification.id,
    );

    // Особенность метода в том, что если не передать ни одного идентификатора
    // то будут прочитаны все уведомления.
    if (!notificationIds.length) {
      return;
    }

    readNotifications(notificationIds);
  }, [notifications, readNotifications]);

  const handleNotificationClick = useCallback(
    (id: number) => {
      const notificationRecord = notifications.find(
        (notification) => notification.id === id,
      );

      if (!notificationRecord) {
        return;
      }

      // Помечаем уведомление прочитанным
      readNotification(notificationRecord.id);

      // Если релиз был разрешен к публикации, переходим сразу на страницу релизов.
      if (notificationRecord.state === ReleaseStatus.approved) {
        navigate(`/apps/${notificationRecord.application.id}/releases`);
        return;
      }

      // Переадресуем пользователя на страницу с историей смены статусов
      navigate(`/apps/${notificationRecord.application.id}/history`);
    },
    [navigate, notifications, readNotification],
  );

  return (
    <HeaderStyled>
      <ButtonCollapseStyled
        collapsed={isSiderCollapsed}
        type="primary"
        icon={
          isSiderCollapsed ? <ChevronForwardIcon /> : <ChevronBackwardIcon />
        }
        size="large"
        onClick={onCollapseClick}
      />
      <LogoStyled hidden={!isSiderCollapsed} />
      <NotificationsDropdown
        items={notificationItems}
        onClick={handleNotificationClick}
        onRead={handleNotificationRead}
        onReadAll={handleAllNotificationsRead}
      />
      <Flex justify="space-between" gap={16} wrap="wrap">
        <Dropdown
          trigger={['click', 'contextMenu']}
          menu={{ items: userMenu, onClick: handleMenuClick }}
          placement="bottom"
        >
          <ButtonUserStyled
            type="text"
            shape="default"
            size="large"
            style={{ fontSize: 24 }}
          >
            <Avatar size={24} icon={<UserOutlined />} />
            <UserNameStyled
              style={{
                fontWeight: 500,
              }}
            >
              {userMail}
            </UserNameStyled>
          </ButtonUserStyled>
        </Dropdown>
      </Flex>
    </HeaderStyled>
  );
}

export default Header;
