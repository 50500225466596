import { useTranslation } from 'react-i18next';

import { ReleaseStatus } from '@/features/release/release.types';

/**
 * Локализованные элементы для фильтра статусов релиза
 * @param includeItemAll - включать ли в список элемент "все".
 */
function useReleaseStateDropdownItems(includeItemAll: boolean = true) {
  const { t } = useTranslation();

  const items = [
    {
      key: ReleaseStatus.created,
      label: t('features.release.statusCreated'),
    },
    {
      key: ReleaseStatus.sentForReview,
      label: t('features.release.statusSentForReview'),
    },
    {
      key: ReleaseStatus.onReview,
      label: t('features.release.statusOnReview'),
    },
    {
      key: ReleaseStatus.approved,
      label: t('features.release.statusApproved'),
    },
    {
      key: ReleaseStatus.rejected,
      label: t('features.release.statusRejected'),
    },
    {
      key: ReleaseStatus.published,
      label: t('features.release.statusPublished'),
    },
    {
      key: ReleaseStatus.banned,
      label: t('features.release.statusBanned'),
    },
  ];

  if (!includeItemAll) {
    return items;
  }

  return [
    ...items,
    {
      key: 'all',
      label: t('features.release.statusAll'),
    },
  ];
}

export default useReleaseStateDropdownItems;
