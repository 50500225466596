/** Ссылка на изображение, обозначающее отсутствующую иконку */
export const NO_ICON = '/images/no-icon.png';

/** Максимальная длина имени приложения */
export const APP_NAME_MAX_LENGTH = 150;

/** Максимальная длина идентификатора приложения */
export const APP_ID_MAX_LENGTH = 150;

/** Запрашиваем данные повторно раз в 10 минут */
export const REVIEW_POLLING_RATE = 600000;
