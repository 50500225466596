import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard, Typography } from '@aq_mobile/ui-kit';
import { MARGINS, settings } from '@aq_mobile/ui-kit/utils';
import { Col, Row, Select } from 'antd';

import { useAppSelector } from '@/features/hooks';
import {
  userMailSelector,
  userRoleSelector,
} from '@/features/user/user.selectors';
import { getUserLabelLocalized } from '@/features/user/user.utils';
import { LANGUAGE_OPTIONS } from '@/locales';

/**
 * Раздел профиля пользователя
 */
export default function Profile() {
  const navigate = useNavigate();
  const email = useAppSelector(userMailSelector);
  const role = useAppSelector(userRoleSelector);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const handleLanguageChange = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      settings.set('language', language);
    },
    [i18n],
  );

  return (
    <ContentCard>
      <ContentCard.Header title={t('routes.Profile.title')} />
      <ContentCard.Body>
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={4}>
            <Typography.TextXS>{t('routes.Profile.email')}</Typography.TextXS>
          </Col>
          <Col md={18}>
            <Typography.TextS>{email}</Typography.TextS>
          </Col>
        </Row>
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={4}>
            <Typography.TextXS>{t('routes.Profile.role')}</Typography.TextXS>
          </Col>
          <Col md={18}>
            <Typography.TextS>
              {getUserLabelLocalized(t, role)}
            </Typography.TextS>
          </Col>
        </Row>
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={4}>
            <Typography.TextXS>
              {t('routes.Profile.language')}
            </Typography.TextXS>
          </Col>
          <Col md={18}>
            <Select
              options={LANGUAGE_OPTIONS}
              value={language}
              onChange={handleLanguageChange}
            />
          </Col>
        </Row>
      </ContentCard.Body>
      <ContentCard.Footer>
        <Button type="default" onClick={() => navigate('/dashboard')}>
          {t('routes.Profile.ok')}
        </Button>
      </ContentCard.Footer>
    </ContentCard>
  );
}
