import { TFunction } from 'i18next';

import { UserRole } from './user.types';

export function getUserLabelLocalized(
  t: TFunction<'translation', undefined>,
  userRole: UserRole,
): string {
  switch (userRole) {
    case 'admin':
      return t('features.user.admin');
    case 'developer':
      return t('features.user.developer');
    case 'moderator':
      return t('features.user.moderator');
    case 'user':
    default:
      return t('features.user.user');
  }
}
