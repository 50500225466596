import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard, PaginationTotal, Table } from '@aq_mobile/ui-kit';
import {
  ArrowDownToLineIcon,
  MagnifyingGlassIcon,
} from '@aq_mobile/ui-kit/icons';
import {
  dateToLocaleString,
  goToURL,
  strftimeToJsDate,
} from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Input, Row, Spin, TableColumnsType } from 'antd';
import { i18n, TFunction } from 'i18next';

import { useGetReviewReleasesQuery } from '@/features/api';
import { Release } from '@/features/release';
import filterByTerm from '@/utils/filterByTerm';
import getTableLocale from '@/utils/getTableLocale';

const APKButtonStyled = styled(Button)``;

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

function getColumnsTemplate(
  t: TFunction<'translation', undefined>,
  i18n: i18n,
): TableColumnsType<Release> {
  return [
    {
      key: 'name',
      title: t('routes.ReviewReleases.columnName'),
      dataIndex: ['application', 'name'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        const dateA = strftimeToJsDate(a.application.name);
        const dateB = strftimeToJsDate(b.application.name);

        if (dateA === null) {
          return 1;
        }

        if (dateB === null) {
          return -1;
        }

        return Number(dateA) - Number(dateB);
      },
    },
    {
      key: 'apk',
      title: t('routes.ReviewReleases.columnAPK'),
      dataIndex: ['apk', 'file'],
      responsive: ['md'],
      render: (apkLink?: string) => {
        if (!apkLink) {
          return null;
        }

        return (
          <APKButtonStyled
            icon={<ArrowDownToLineIcon />}
            onClick={(e) => {
              e.stopPropagation();
              goToURL(apkLink);
            }}
          />
        );
      },
    },
    {
      key: 'updated_at',
      title: t('routes.ReviewReleases.columnDate'),
      dataIndex: ['releasestate', 'updated_at'],
      render: (dateStr?: string) => {
        if (!dateStr) {
          return null;
        }

        return dateToLocaleString(strftimeToJsDate(dateStr), i18n.language);
      },
      ellipsis: true,
    },
    {
      key: 'developer',
      title: t('routes.ReviewReleases.columnDeveloper'),
      dataIndex: ['application', 'developer'],
    },
  ];
}

const PAGE_SIZE = 8;

/**
 * Страница со списком приложений на модерации у пользователя
 */
export default function ReviewReleases() {
  const { t, i18n } = useTranslation();
  const [currentAppTablePage, setCurrentAppTablePage] = useState(1);
  const { data, isLoading } = useGetReviewReleasesQuery();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleGoToRelease = useCallback(
    ({ id, application: { id: application_id } }: Release) => {
      navigate(`/apps/${application_id}/release/`);
    },
    [navigate],
  );

  const columnsTemplate = useMemo(() => getColumnsTemplate(t, i18n), [t, i18n]);
  const tableLocale = useMemo(
    () => getTableLocale(i18n.language),
    [i18n.language],
  );

  const tableData = useMemo(() => {
    const releases = data?.results || [];

    if (!releases.length) {
      return [];
    }

    const nameFiltered = filterByTerm(releases, searchTerm, [
      'application',
      'name',
    ]);

    return nameFiltered;
  }, [data?.results, searchTerm]);

  const TableControlsTemplate = useMemo(() => {
    const SearchField = (
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={t('routes.ReviewReleases.search')}
        suffix={<MagnifyingGlassIcon />}
        allowClear
      />
    );
    return SearchField;
  }, [searchTerm, t]);

  return (
    <ContentCard>
      <ContentCard.Header
        title={t('routes.ReviewReleases.applicationsOnReview')}
      />

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col>{TableControlsTemplate}</Col>
          <Col flex="auto"></Col>
          <Col>
            <PaginationTotal
              currentPage={currentAppTablePage}
              pageSize={PAGE_SIZE}
              total={tableData.length || 0}
            />
          </Col>
        </Row>

        <Spin spinning={isLoading}>
          <TableStyled
            dataSource={tableData}
            columns={columnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentAppTablePage(page);
              },
            }}
            locale={tableLocale}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  handleGoToRelease(record);
                },
              };
            }}
            scroll={{ x: 768 }}
          />
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
