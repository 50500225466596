import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ContentCard,
  Dropdown,
  Modal,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import { MagnifyingGlassIcon, PlusIcon } from '@aq_mobile/ui-kit/icons';
import { goToURL } from '@aq_mobile/ui-kit/utils';
import { Col, Input, Row, Space, Spin, TableColumnsType } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { TFunction } from 'i18next';

import {
  User,
  UserRole,
  USERS_SORT_COLUMN_SETTING,
  useUsers,
} from '@/features/user';
import { getUserLabelLocalized } from '@/features/user/user.utils';
import { checkFilterMatch } from '@/utils/filterByTerm';
import getTableLocale from '@/utils/getTableLocale';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const PAGE_SIZE = 12;

function getColumnsTemplate(
  t: TFunction<'translation', undefined>,
): TableColumnsType<User> {
  return [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('routes.Users.tableEmail'),
      defaultSortOrder: getSortColumnSettings(
        USERS_SORT_COLUMN_SETTING,
        'email',
        'ascend',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: t('routes.Users.tableRole'),
      defaultSortOrder: getSortColumnSettings(
        USERS_SORT_COLUMN_SETTING,
        'role',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        return a.role.toLocaleUpperCase() > b.role.toLocaleUpperCase() ? 1 : -1;
      },
      render: (role: UserRole) => getUserLabelLocalized(t, role),
    },
  ];
}

/** Раздел со списком пользователей */
export default function Users() {
  const { t, i18n } = useTranslation();
  const { users, isLoading } = useUsers();
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  /** Константа, использующаяся как набор возможных значений для списка пользователей */
  const USER_ROLE_ITEMS = useMemo(
    () => [
      {
        key: 'developer',
        label: getUserLabelLocalized(t, 'developer'),
      },
      {
        key: 'moderator',
        label: getUserLabelLocalized(t, 'moderator'),
      },
      {
        key: 'admin',
        label: getUserLabelLocalized(t, 'admin'),
      },
      {
        key: 'all',
        label: t('routes.Users.allRoles'),
      },
    ],
    [t],
  );
  const [roleFilter, setRoleFilter] = useState<{ key: string; label: string }>(
    USER_ROLE_ITEMS[USER_ROLE_ITEMS.length - 1],
  );
  const columnsTemplate = useMemo(() => getColumnsTemplate(t), [t]);
  const tableData = useMemo(() => {
    if (!users.length) {
      return [];
    }

    let filteredUsers = users;
    if (roleFilter.key !== 'all') {
      filteredUsers = users.filter(
        (user) => user.role.toString() === roleFilter.key,
      );
    }

    if (!textFilter) {
      return filteredUsers;
    }

    filteredUsers = users.filter((user) => {
      const isEmailMatch = checkFilterMatch(user.email, textFilter);
      if (isEmailMatch) {
        return true;
      }

      const isNameMatch = checkFilterMatch(user.first_name, textFilter);
      if (isNameMatch) {
        return true;
      }

      const isLastNameMatch = checkFilterMatch(user.last_name, textFilter);
      if (isLastNameMatch) {
        return true;
      }

      return false;
    });

    return filteredUsers;
  }, [roleFilter.key, textFilter, users]);

  const handleNewButtonClick = useCallback(() => setIsModalOpen(true), []);
  const handleGoToAuthService = useCallback(() => {
    setIsModalOpen(false);
    goToURL(__AUTH_SERVICE_ADDRESS__);
  }, []);
  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      if (['email', 'role'].includes(sorter.columnKey.toString())) {
        setSortColumnSettings(
          USERS_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  const tableLocale = useMemo(
    () => getTableLocale(i18n.language),
    [i18n.language],
  );

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title={t('routes.Users.title')}
          buttons={
            <Button
              type="primary"
              icon={<PlusIcon />}
              onClick={handleNewButtonClick}
            >
              {t('routes.Users.addUser')}
            </Button>
          }
        />
        <ContentCard.Body>
          <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
            <Col>
              <Input
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
                placeholder={t('routes.Users.search')}
                suffix={<MagnifyingGlassIcon />}
                allowClear
              />
            </Col>
            <Col flex="auto"></Col>
            <Col>
              <Space>
                <Typography.TextS>{t('routes.Users.role')}</Typography.TextS>
                <Dropdown
                  items={USER_ROLE_ITEMS}
                  selectedText={roleFilter.label}
                  onSelect={setRoleFilter}
                />
              </Space>
            </Col>
            <Col>
              <PaginationTotal
                currentPage={currentTablePage}
                pageSize={PAGE_SIZE}
                total={tableData.length || 0}
              />
            </Col>
          </Row>

          <Spin spinning={isLoading}>
            <Table
              dataSource={tableData}
              columns={columnsTemplate}
              rowKey={'id'}
              pagination={{
                pageSize: PAGE_SIZE,
                hideOnSinglePage: false,
                onChange(page) {
                  setCurrentTablePage(page);
                },
              }}
              onChange={handleTableChange}
              locale={tableLocale}
              scroll={{ x: 768 }}
            />
          </Spin>
        </ContentCard.Body>
      </ContentCard>
      <Modal
        title={t('routes.Users.addUserModal')}
        onOk={handleGoToAuthService}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
      >
        <Typography.TextS>
          {t('routes.Users.addUserModalDescription')}
        </Typography.TextS>
      </Modal>
    </>
  );
}
