import { initReactI18next } from 'react-i18next';
import {
  TranslationEn as componentsTranslationEn,
  TranslationRu as componentsTranslationRu,
} from '@aq_mobile/ui-kit';
import { settings } from '@aq_mobile/ui-kit/utils';
import i18n from 'i18next';

import { translationEnglish, translationRussian } from '@/locales';

const resources = {
  en: {
    translation: {
      ...componentsTranslationEn,
      ...translationEnglish,
    },
  },
  ru: {
    translation: {
      ...componentsTranslationRu,
      ...translationRussian,
    },
  },
};

const language = settings.get('language', 'ru') as string;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: language,
    debug: true,
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
