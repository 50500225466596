import localeEN from 'antd/locale/en_US';
import localeRU from 'antd/locale/ru_RU';

/**
 * Возвращает локализацию для таблицы, в зависимости от переданного кода языка.
 * По умолчанию возвращает undefined, что означает English
 */
function getTableLocale(locale: string) {
  switch (locale) {
    case 'ru':
      return localeRU.Table;
    default:
      return localeEN.Table;
  }
}

export default getTableLocale;
