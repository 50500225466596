import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@aq_mobile/ui-kit';
import styled from '@emotion/styled';

import useAppToken from '@/themes/useAppToken';

const ContentWrapperStyled = styled.div`
  max-width: 296px;
  padding: 2em;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const FourOFourStyled = styled.h1<{ textColor: string }>`
  font-size: 128px;
  color: ${(props) => props.textColor};
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin: 0;
`;

const LogoStyled = styled(Typography.Heading3)`
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  margin-block-end: 40px;
`;

export default function NotFound() {
  const { t } = useTranslation();
  const { token } = useAppToken();
  const navigate = useNavigate();
  const handleButtonClick = useCallback(() => navigate('/'), [navigate]);
  const forOFourColor = token.colorPrimary;

  return (
    <ContentWrapperStyled>
      <LogoStyled>{t('routes.NotFound.title')}</LogoStyled>

      <FourOFourStyled textColor={forOFourColor}>
        {t('routes.NotFound.subtitle')}
      </FourOFourStyled>

      <Typography.Heading3
        style={{
          marginBlockStart: 8,
          marginBlockEnd: 8,
        }}
      >
        {t('routes.NotFound.noSuchPage')}
      </Typography.Heading3>

      <Typography.ParagraphM>
        {t('routes.NotFound.explanation')}
      </Typography.ParagraphM>

      <Button
        type="primary"
        onClick={handleButtonClick}
        style={{ marginBlockStart: 32 }}
      >
        {t('routes.NotFound.returnToMainPage')}
      </Button>
    </ContentWrapperStyled>
  );
}
