import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard } from '@aq_mobile/ui-kit';
import { Form, Input, Spin } from 'antd';

import { NavigationBlocker } from '@/components';
import { APP_ID_MAX_LENGTH, APP_NAME_MAX_LENGTH } from '@/constants';
import { NewApplicationRequest } from '@/features/api';
import { useApplicationEdit } from '@/features/application';
import validateAndroidPackageId from '@/utils/validateAndroidPackageId';

type NewApplicationForm = {
  name: string;
  appId: string;
};

/**
 * Компонент создания нового приложения в магазине.
 */
export default function ApplicationNew() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { createApplication, isApplicationCreating } = useApplicationEdit();
  /** Признак, используемый для отображения окна с предупреждением, при попытке уйти с редактируемой формы */
  const [isFormEdited, setIsFormEdited] = useState(false);

  const addNewApp = useCallback(
    async (formValues: NewApplicationForm) => {
      const payload = {
        name: formValues.name,
        app_id: formValues.appId,
      } as NewApplicationRequest;

      try {
        const result = await createApplication(payload).unwrap();

        setIsFormEdited(false);

        setTimeout(() => navigate(`/apps/${result.id}`), 100);
      } catch (error) {
        // Обработчик ошибок находится в useApplicationEdit.
      }
    },
    [createApplication, navigate],
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
    setIsFormEdited(false);

    setTimeout(() => navigate('/apps'), 100);
  }, [form, navigate]);

  const handleValuesChange = useCallback(() => {
    const isTouched = form.isFieldsTouched();

    if (isFormEdited === isTouched) {
      return;
    }

    setIsFormEdited(isTouched);
  }, [form, isFormEdited]);

  const isPending = isApplicationCreating;

  return (
    <>
      <ContentCard>
        <ContentCard.Header title={t('routes.ApplicationNew.title')} />
        <ContentCard.Body>
          <Spin tip={t('routes.ApplicationNew.loading')} spinning={isPending}>
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ group: 'all' }}
              onFinish={addNewApp}
              labelWrap
              colon={false}
              requiredMark={false}
              labelAlign="left"
              onValuesChange={handleValuesChange}
            >
              <Form.Item<NewApplicationForm>
                label={t('routes.ApplicationNew.applicationName')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('routes.ApplicationNew.applicationNameRequired'),
                  },
                ]}
              >
                <Input showCount maxLength={APP_NAME_MAX_LENGTH} />
              </Form.Item>
              <Form.Item<NewApplicationForm>
                label={t('routes.ApplicationNew.applicationID')}
                name="appId"
                rules={[
                  {
                    required: true,
                    message: t('routes.ApplicationNew.applicationIDRequired'),
                  },
                  () => ({
                    validator(_, value: string) {
                      if (!value || !value.length) {
                        return Promise.resolve();
                      }

                      const isMatch = validateAndroidPackageId(value);

                      if (isMatch) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        t('routes.ApplicationNew.applicationIDNotComplyFormat'),
                      );
                    },
                  }),
                ]}
              >
                <Input showCount maxLength={APP_ID_MAX_LENGTH} />
              </Form.Item>
            </Form>
          </Spin>
        </ContentCard.Body>
        <ContentCard.Footer
          buttons={
            <>
              <Button
                type="primary"
                onClick={form.submit}
                disabled={isApplicationCreating}
              >
                {t('routes.ApplicationNew.add')}
              </Button>
              <Button type="default" onClick={handleCancel}>
                {t('routes.ApplicationNew.cancel')}
              </Button>
            </>
          }
        ></ContentCard.Footer>
      </ContentCard>

      <NavigationBlocker shouldBlock={isFormEdited} />
    </>
  );
}
