import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ServerError, useGetAppQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук, для управления приложением.
 */
function useApplication(id?: string | undefined) {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isApplicationLoading,
    data: applicationData,
    error: applicationError,
    isSuccess: isApplicationLoadingSuccess,
  } = useGetAppQuery(id!, { skip: Boolean(!id) });
  const navigate = useNavigate();

  useEffect(() => {
    if (!applicationError) {
      return;
    }

    const serverError = applicationError as unknown as ServerError;

    if (serverError.status === 404) {
      navigate('/not-found');
    }

    const messages = getServerErrorDescriptions(serverError);

    notificationContext.showError(
      t('features.application.getApplicationError'),
      messages,
    );
    console.error(
      t('features.application.getApplicationError'),
      applicationError,
    );
  }, [applicationError, navigate, notificationContext, t]);

  return {
    isApplicationLoading,
    applicationData,
    applicationError,
    isApplicationLoadingSuccess,
  };
}

export default useApplication;
