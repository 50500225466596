import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ServerError,
  useStoreDeleteMutation,
  useStoreUpdateMutation,
  useStoreUploadMutation,
} from '@/features/api';
import { getServerErrorMessage } from '@/features/api/api.utils';
import { getAPKErrorMessage } from '@/features/apk/apk.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Функционал редактирования APK магазина приложений
 */
function useAPKEdit() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const [
    deleteStoreAPK,
    {
      isLoading: isStoreAPKDeleting,
      error: deleteStoreAPKError,
      isSuccess: isStoreAPKDeleteSuccess,
    },
  ] = useStoreDeleteMutation();

  const [
    updateStoreAPK,
    {
      isLoading: isStoreAPKUpdating,
      error: updateStoreAPKError,
      isSuccess: isStoreAPKUpdateSuccess,
    },
  ] = useStoreUpdateMutation();
  const [
    uploadStoreAPK,
    {
      isLoading: isStoreAPKUploading,
      error: uploadStoreAPKError,
      isSuccess: isStoreAPKUploadSuccess,
    },
  ] = useStoreUploadMutation();

  useEffect(() => {
    if (!deleteStoreAPKError) {
      return;
    }

    notificationContext.showError(
      t('utils.deleteError'),
      t('features.storeAPK.deleteError'),
    );
    console.error(t('features.storeAPK.deleteError'), deleteStoreAPKError);
  }, [deleteStoreAPKError, notificationContext, t]);

  useEffect(() => {
    if (!updateStoreAPKError) {
      return;
    }

    notificationContext.showError(
      t('utils.updateError'),
      t('features.storeAPK.updateError'),
    );
    console.error(t('features.storeAPK.updateError'), updateStoreAPKError);
  }, [notificationContext, updateStoreAPKError, t]);

  useEffect(() => {
    if (!uploadStoreAPKError) {
      return;
    }

    const errors = getAPKErrorMessage(
      uploadStoreAPKError as unknown as ServerError,
      t('features.storeAPK.alreadyUploaded'),
    );
    const restErrors = getServerErrorMessage(errors.unknownErrors);

    const combinedErrors = [...errors.errorMessages, ...restErrors];

    notificationContext.showError(t('utils.createError'), combinedErrors);
    console.error(t('features.storeAPK.createError'), uploadStoreAPKError);
  }, [notificationContext, uploadStoreAPKError, t]);

  const isStoreAPKInProgress =
    isStoreAPKDeleting || isStoreAPKUpdating || isStoreAPKUploading;

  return {
    deleteStoreAPK,
    isStoreAPKDeleting,
    deleteStoreAPKError,
    isStoreAPKDeleteSuccess,
    updateStoreAPK,
    isStoreAPKUpdating,
    updateStoreAPKError,
    isStoreAPKUpdateSuccess,
    uploadStoreAPK,
    isStoreAPKUploading,
    uploadStoreAPKError,
    isStoreAPKUploadSuccess,
    isStoreAPKInProgress,
  };
}

export default useAPKEdit;
