import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ServerError, useGetReleaseQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Возвращает информацию по указанному релизу.
 */
function useRelease(application_id: string, id: number) {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const {
    data: release,
    isLoading,
    error: releaseError,
    isSuccess,
  } = useGetReleaseQuery({
    application_id,
    id,
  });

  useEffect(() => {
    // Обработчик ошибки получения релиза
    if (!releaseError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      releaseError as unknown as ServerError,
    );
    notificationContext.showError(
      t('features.release.getReleaseError'),
      messages,
    );
  }, [notificationContext, releaseError, t]);

  return {
    /**
     * Ссылка на релиз, на основе которого будет формироваться текущий.
     *
     * undefined - информация о релизе еще не получена
     * null - для приложения еще нет сохраненных релизов
     * Release - предыдущий релиз.
     */
    release,
    isLoading,
    isSuccess,
    releaseError,
  };
}

export default useRelease;
