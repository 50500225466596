import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  CommentModal,
  ContentCard,
  NoRelease,
} from '@aq_mobile/ui-kit';
import { PlusIcon } from '@aq_mobile/ui-kit/icons';
import { strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Spin } from 'antd';

import { ReleaseCard } from '@/components';
import { useApplication } from '@/features/application';
import { useModifyReleaseState, useReleases } from '@/features/release';
import useAppToken from '@/themes/useAppToken';

const AddButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'borderColor',
})<{ borderColor: string }>`
  width: 100%;
  border: 1px dashed ${(props) => props.borderColor};
`;

const FullScreenSpin = styled(Spin)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapperDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
`;

/**
 * Раздел со списком релизов
 */
export default function Releases() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { token } = useAppToken();
  const application_id = id!;
  const { isApplicationLoading, applicationData } = useApplication(id);
  const {
    publishedRelease,
    editingRelease,
    isLoading: isReleasesLoading,
    error: getReleasesError,
  } = useReleases(application_id);
  const {
    publish,
    ban,
    isPublishLoading,
    isBanLoading,
    sendForReview,
    isSendForReviewLoading,
  } = useModifyReleaseState();
  const navigate = useNavigate();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const addButtonBorder = token.AppAddReleaseButton.borderColor;

  const handleCreate = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const handleGoToRelease = useCallback(
    (id: number) => navigate(`${id}`),
    [navigate],
  );

  const handleSendForReview = useCallback(
    (id: number, comment?: string) => {
      sendForReview({ application_id, id, text: comment });
    },
    [application_id, sendForReview],
  );

  const handleUnpublish = useCallback(
    (id: number) => {
      ban({ application_id, id });
    },
    [application_id, ban],
  );

  const handlePublish = useCallback(
    (id: number) => {
      publish({ application_id, id });
    },
    [application_id, publish],
  );

  const isPageInProgress =
    isApplicationLoading ||
    isReleasesLoading ||
    isPublishLoading ||
    isBanLoading ||
    isSendForReviewLoading;

  const publishedReleaseCardTemplate = useMemo(() => {
    if (!publishedRelease) {
      return null;
    }

    const id = publishedRelease.id;
    const releaseTitle = t('routes.Releases.releaseTitle', {
      releaseName: publishedRelease.apk.release_name,
    });
    const description = publishedRelease.release_notes;
    const changeDate = strftimeToJsDate(publishedRelease.apk.release_date);
    const state = publishedRelease.releasestate.state;

    return (
      <ReleaseCard
        title={releaseTitle}
        description={description}
        date={changeDate}
        status={state}
        onUnpublish={() => handleUnpublish(id)}
        onOpen={() => handleGoToRelease(id)}
      />
    );
  }, [publishedRelease, t, handleUnpublish, handleGoToRelease]);

  const lastReleaseCardTemplate = useMemo(() => {
    if (!editingRelease) {
      return null;
    }

    const {
      id,
      release_notes,
      releasestate: { state, updated_at },
      apk: { release_name },
    } = editingRelease;
    const releaseTitle = t('routes.Releases.releaseTitle', {
      releaseName: release_name,
    });
    const changeDate = strftimeToJsDate(updated_at);

    return (
      <ReleaseCard
        title={releaseTitle}
        description={release_notes}
        date={changeDate}
        status={state}
        onOpen={() => handleGoToRelease(id)}
        onChange={() => handleCreate()}
        onSend={() => setIsCommentModalOpen(true)}
        onUnpublish={() => handleUnpublish(id)}
        onPublish={() => handlePublish(id)}
      />
    );
  }, [
    editingRelease,
    t,
    handleGoToRelease,
    handleCreate,
    handleUnpublish,
    handlePublish,
  ]);

  const noReleaseTemplate = useMemo(() => {
    if (getReleasesError) {
      return null;
    }

    if (editingRelease || publishedRelease) {
      return null;
    }

    return <NoRelease onButtonClick={handleCreate} />;
  }, [getReleasesError, handleCreate, editingRelease, publishedRelease]);

  const addButtonTemplate = useMemo(() => {
    if (publishedRelease && !editingRelease) {
      return (
        <AddButtonStyled
          borderColor={addButtonBorder}
          type="text"
          icon={<PlusIcon style={{ fontSize: 9 }} />}
          onClick={handleCreate}
        >
          {t('routes.Releases.newRelease')}
        </AddButtonStyled>
      );
    }

    return null;
  }, [publishedRelease, editingRelease, addButtonBorder, handleCreate, t]);

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title={t('routes.Releases.title')}
          chip={
            <Spin spinning={isApplicationLoading}>
              <Chip type="info">{applicationData?.name}</Chip>
            </Spin>
          }
        />
        <ContentCard.Body>
          {isPageInProgress && (
            <FullScreenSpin spinning={isPageInProgress}></FullScreenSpin>
          )}
          <ContentWrapperDivStyled>
            {noReleaseTemplate}
            {publishedReleaseCardTemplate}
            {addButtonTemplate}
            {lastReleaseCardTemplate}
          </ContentWrapperDivStyled>
        </ContentCard.Body>
      </ContentCard>

      <CommentModal
        title={t('routes.Releases.sendOnReviewModal')}
        label={t('routes.Releases.commentForReviewer')}
        open={isCommentModalOpen}
        onOk={(comment) => {
          setIsCommentModalOpen(false);
          if (!editingRelease) {
            return;
          }

          handleSendForReview(editingRelease.id, comment);
        }}
        onCancel={() => setIsCommentModalOpen(false)}
      />
    </>
  );
}
