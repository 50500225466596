import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReviewCard } from '@/components';
import { REVIEW_POLLING_RATE } from '@/constants';
import { ServerError, useLazyGetReviewQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { useModifyReleaseState } from '@/features/release';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Раздел взятия релиза на модерацию.
 */
export default function Review() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const navigate = useNavigate();
  const [getReview, { data, isLoading, isUninitialized, error }] =
    useLazyGetReviewQuery({
      pollingInterval: REVIEW_POLLING_RATE,
      refetchOnReconnect: true,
    });
  const { takeForReview, isTakeForReviewLoading } = useModifyReleaseState();

  useEffect(() => {
    // Автоматически выполняет запрос на релиза на проверку,
    if (!isUninitialized) {
      return;
    }

    getReview();
  }, [getReview, isUninitialized]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const serverError = error as unknown as ServerError;

    const messages = getServerErrorDescriptions(serverError);

    notificationContext.showError(
      t('routes.Review.releasesLoadError'),
      messages,
    );
    console.error(t('routes.Review.releasesLoadError'), error);
  }, [error, navigate, notificationContext, t]);

  const handleTakeOnReview = useCallback(async () => {
    if (!data) {
      return;
    }

    const {
      application: { id: application_id },
      id,
      releasestate: { text },
    } = data;

    try {
      await takeForReview({
        application_id: application_id.toString(),
        id,
        /* Копируем текст комментария от разработчика, т.к. он должен отображаться на странице модерации */
        text,
      }).unwrap();

      navigate(`/apps/${application_id}/release/`);
    } catch (e) {
      console.error(
        t('routes.Review.errorWhileTakingOnReview', {
          id,
          application_id,
        }),
        e,
      );
    }
  }, [data, navigate, takeForReview, t]);

  const handleRefresh = useCallback(() => {
    getReview();
  }, [getReview]);

  const cardData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const {
      application: { name },
      icon: { file },
      release_notes,
    } = data;

    return {
      title: name,
      icon: file,
      description: release_notes,
    };
  }, [data]);

  return (
    <div style={{ width: '100%' }}>
      <ReviewCard
        data={cardData}
        isLoading={isLoading}
        isPending={isTakeForReviewLoading}
        isErrored={Boolean(error)}
        onActionClick={handleTakeOnReview}
        onRefreshClick={handleRefresh}
      />
    </div>
  );
}
