import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useReadNotificationMutation,
  useReadNotificationsMutation,
} from '@/features/api';
import { NotificationContext } from '@/utils/notification-context';

/** Функции для управления уведомлениями */
function useNotificationEdit() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const [
    readNotification,
    { isLoading: isReadNotificationLoading, error: readNotificationError },
  ] = useReadNotificationMutation();
  const [
    readNotifications,
    { isLoading: isReadNotificationsLoading, error: readNotificationsError },
  ] = useReadNotificationsMutation();

  useEffect(() => {
    if (!readNotificationError) {
      return;
    }

    notificationContext.showError(
      t('features.notifications.markReadErrorSingle'),
    );
  }, [readNotificationError, notificationContext, t]);

  useEffect(() => {
    if (!readNotificationsError) {
      return;
    }

    notificationContext.showError(
      t('features.notifications.markReadErrorMultiple'),
    );
  }, [readNotificationsError, notificationContext, t]);

  return {
    readNotification,
    readNotifications,
    isReadNotificationLoading,
    isReadNotificationsLoading,
    readNotificationError,
  };
}

export default useNotificationEdit;
