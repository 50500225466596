import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  ContentCard,
  Dropdown,
  DropDownItemStringKey,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import { ArrowDownToLineIcon } from '@aq_mobile/ui-kit/icons';
import {
  dateToLocaleString,
  goToURL,
  strftimeToJsDate,
} from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Row, Space, Spin, TableColumnsType, TableColumnType } from 'antd';
import { i18n, TFunction } from 'i18next';

import { ReleaseStateModal } from '@/components';
import { useApplication } from '@/features/application';
import {
  getReleaseStatusTranslationKey,
  Release,
  ReleaseStatus,
  releaseStatusToChipType,
  useModifyReleaseState,
  useReleases,
  useReleaseStateDropdownItems,
} from '@/features/release';
import { useUser } from '@/features/user';
import getTableLocale from '@/utils/getTableLocale';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

function getColumnsTemplate(
  t: TFunction<'translation', undefined>,
  i18n: i18n,
): TableColumnsType<Release> {
  return [
    {
      key: 'updated_at',
      title: t('routes.ReleaseAdmin.columnDate'),
      dataIndex: ['releasestate', 'updated_at'],
      render: (dateStr?: string) => {
        if (!dateStr) {
          return null;
        }

        return dateToLocaleString(strftimeToJsDate(dateStr), i18n.language);
      },
      ellipsis: true,
    },
    {
      key: 'apk',
      title: 'APK',
      dataIndex: ['apk', 'file'],
      responsive: ['md'],
      render: (apkLink?: string) => {
        if (!apkLink) {
          return null;
        }

        return (
          <Button
            icon={<ArrowDownToLineIcon />}
            onClick={(e) => {
              e.stopPropagation();
              goToURL(apkLink);
            }}
          />
        );
      },
    },
    {
      key: 'developer',
      title: t('routes.ReleaseAdmin.columnDeveloper'),
      dataIndex: ['application', 'developer'],
    },
    {
      key: 'state',
      title: t('routes.ReleaseAdmin.columnStatus'),
      dataIndex: ['releasestate', 'state'],
      render: (status: ReleaseStatus) => {
        return (
          <Chip type={releaseStatusToChipType(status)}>
            {t(getReleaseStatusTranslationKey(status))}
          </Chip>
        );
      },
      ellipsis: true,
      width: 220,
    },
  ];
}

const PAGE_SIZE = 8;

/**
 * Раздел со списком релизов приложения, доступный администратору.
 */
export default function ReleasesAdmin() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [currentAppTablePage, setCurrentAppTablePage] = useState(1);
  const application_id = id!;
  const { isApplicationLoading, applicationData } = useApplication(id);
  const { releases, isLoading: isReleasesLoading } =
    useReleases(application_id);
  /** Максимальное количество релизов для одного приложения 2.
   * И только один релиз может быть на модерации.
   * Поэтому мы можем получить информацию по модератору для таблицы тут
   * иначе это лучше делать на сервере и сразу присылать обогащенные записи */
  const [moderator, setModerator] = useState<number | undefined>();
  const { user } = useUser(moderator);
  const { editReleaseState, isEditReleaseStateLoading } =
    useModifyReleaseState();
  const navigate = useNavigate();
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState<ReleaseStatus>(
    ReleaseStatus.created,
  );
  const [editingReleaseId, setEditingReleaseId] = useState<
    number | undefined
  >();
  const stateFilterItems = useReleaseStateDropdownItems(true);
  const [stateFilter, setStateFilter] = useState(
    stateFilterItems[stateFilterItems.length - 1],
  );

  const tableData = useMemo(() => {
    if (!releases.length) {
      return [];
    }

    const filteredData = releases.filter((row) => {
      const isAllowAll = stateFilter.key === 'all';
      if (isAllowAll) {
        return true;
      }

      const releaseStateFilter = stateFilter.key as ReleaseStatus;

      return row.releasestate.state === releaseStateFilter;
    });

    return filteredData;
  }, [releases, stateFilter.key]);

  const openChangeStateModal = useCallback(
    (id: number, currentState: ReleaseStatus) => {
      setEditingReleaseId(id);
      setSelectedState(currentState);
      setIsStateModalOpen(true);
    },
    [],
  );

  const handleChangeState = useCallback(
    (state: ReleaseStatus) => {
      if (!editingReleaseId) {
        return;
      }

      editReleaseState({
        id: editingReleaseId,
        application_id,
        state: {
          state,
        },
      });
    },
    [application_id, editReleaseState, editingReleaseId],
  );

  const handleGoToRelease = useCallback(
    (id: number) => navigate(`${id}`),
    [navigate],
  );
  const tableColumns = useMemo(() => {
    const columns = [
      ...getColumnsTemplate(t, i18n),
      {
        key: 'state',
        title: t('routes.ReleaseAdmin.columnReviewer'),
        dataIndex: ['releasestate', 'user'],
        render: (_, record) => {
          if (record.releasestate.state !== ReleaseStatus.onReview) {
            return '';
          }

          if (!moderator) {
            setModerator(record.releasestate.user);
            return '';
          }

          if (!user) {
            return '';
          }

          return user.email;
        },
      } as TableColumnType<Release>,
      {
        key: 'id',
        title: t('routes.ReleaseAdmin.columnAction'),
        render: (_, record) => {
          return (
            <Button
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                openChangeStateModal(record.id, record.releasestate.state);
              }}
            >
              Управлять
            </Button>
          );
        },
      } as TableColumnType<Release>,
    ];

    return columns;
  }, [moderator, openChangeStateModal, t, user]);

  const tableLocale = useMemo(
    () => getTableLocale(i18n.language),
    [i18n.language],
  );

  const isPageInProgress =
    isApplicationLoading || isReleasesLoading || isEditReleaseStateLoading;

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title="Релизы"
          chip={
            <Spin spinning={isApplicationLoading}>
              <Chip type="info">{applicationData?.name}</Chip>
            </Spin>
          }
        />
        <ContentCard.Body>
          <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
            <Col flex="auto"></Col>
            <Col>
              <Space>
                <Typography.TextS>Статус:</Typography.TextS>
                <Dropdown<DropDownItemStringKey>
                  items={stateFilterItems}
                  selectedText={stateFilter.label}
                  onSelect={setStateFilter}
                />
              </Space>
            </Col>
            <Col>
              <PaginationTotal
                currentPage={currentAppTablePage}
                pageSize={PAGE_SIZE}
                total={tableData.length || 0}
              />
            </Col>
          </Row>

          <Spin spinning={isPageInProgress}>
            <TableStyled
              dataSource={tableData}
              columns={tableColumns}
              rowKey={'id'}
              pagination={{
                pageSize: PAGE_SIZE,
                hideOnSinglePage: false,
                onChange(page) {
                  setCurrentAppTablePage(page);
                },
              }}
              locale={tableLocale}
              onRow={(record) => {
                return {
                  onClick: () => handleGoToRelease(record.id),
                };
              }}
              scroll={{ x: 768 }}
            />
          </Spin>
        </ContentCard.Body>
      </ContentCard>

      <ReleaseStateModal
        selectedState={selectedState}
        open={isStateModalOpen}
        onOpenChange={setIsStateModalOpen}
        onOk={handleChangeState}
      />
    </>
  );
}
