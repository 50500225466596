import {
  NotificationDropdownIcon,
  NotificationsDropdownItem,
} from '@aq_mobile/ui-kit';
import { TFunction } from 'i18next';

import { ChangelogRecord } from '@/features/changelog';
import { ReleaseStatus } from '@/features/release';

const notificationStates = [
  ReleaseStatus.approved,
  ReleaseStatus.banned,
  ReleaseStatus.onReview,
  ReleaseStatus.rejected,
];

/**
 * Возвращает объект, содержащий сформированное уведомление,
 * относительно переданной записи об изменении состояния.
 */
function mapNotificationToMessage(
  changeRecords: Array<ChangelogRecord>,
  t: TFunction<'translation', undefined>,
): Array<NotificationsDropdownItem> {
  const filteredRecords = changeRecords.filter((record) =>
    notificationStates.includes(record.state),
  );

  const notifications = filteredRecords.map((record) => {
    switch (record.state) {
      case ReleaseStatus.approved:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.success,
          label: t('features.notifications.notificationReleaseApproved', {
            name: record.application.name,
          }),
        };
      case ReleaseStatus.rejected:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.error,
          label: t('features.notifications.notificationReleaseRejected', {
            name: record.application.name,
          }),
        };
      case ReleaseStatus.banned:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.warning,
          label: t('features.notifications.notificationReleaseBanned', {
            name: record.application.name,
          }),
        };
      case ReleaseStatus.onReview:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.info,
          label: t('features.notifications.notificationReleaseOnReview', {
            name: record.application.name,
          }),
        };
      default: {
        return {
          key: record.id,
          icon: NotificationDropdownIcon.info,
          label: t('features.notifications.notificationReleaseDefault', {
            name: record.application.name,
            message: record.text,
          }),
        };
      }
    }
  });

  return notifications;
}

export { mapNotificationToMessage };
