import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FilterValue, Settings } from './settings.types';

const initialState: Settings = {
  applicationsStatusFilter: {
    key: 'all',
  },
  applicationsTypeFilter: {
    key: 'all',
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /** Устанавливает фильтр по статусу на странице списка приложений */
    setApplicationsStatusFilter(state, action: PayloadAction<FilterValue>) {
      state.applicationsStatusFilter = action.payload;
    },
    /** Устанавливает фильтр по типу на странице списка приложений */
    setApplicationsTypeFilter(state, action: PayloadAction<FilterValue>) {
      state.applicationsTypeFilter = action.payload;
    },
  },
});

export default settingsSlice.reducer;
