import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ServerError,
  useAppCreateMutation,
  useAppDeleteMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для редактирования приложения.
 */
function useApplicationEdit() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const [
    deleteApplication,
    {
      isLoading: isApplicationDeleting,
      error: applicationDeleteError,
      isSuccess: isApplicationDeleteSuccess,
    },
  ] = useAppDeleteMutation();
  const [
    createApplication,
    {
      isLoading: isApplicationCreating,
      error: applicationCreateError,
      isSuccess: isApplicationCreateSuccess,
    },
  ] = useAppCreateMutation();

  useEffect(() => {
    if (!applicationDeleteError) {
      return;
    }

    notificationContext.showError(
      t('utils.error'),
      t('features.application.deleteError'),
    );
    console.error(
      t('features.application.deleteError'),
      applicationDeleteError,
    );
  }, [applicationDeleteError, notificationContext, t]);

  useEffect(() => {
    if (!applicationCreateError) {
      return;
    }

    /**
     * Поля приложения и их названия.
     */
    const APPLICATION_FIELDS = {
      name: t('features.application.name'),
      approved: t('features.application.approved'),
      categories: t('features.application.categories'),
      description: t('features.application.description'),
      device_group: t('features.application.device_group'),
      icon: t('features.application.icon'),
      is_system_app: t('features.application.is_system_app'),
      rating: t('features.application.rating'),
    };

    const errors = getServerErrorDescriptions(
      applicationCreateError as unknown as ServerError,
      APPLICATION_FIELDS,
    );

    notificationContext.showError(
      t('features.application.createError'),
      errors,
    );

    console.error(
      t('features.application.createError'),
      applicationCreateError,
    );
  }, [applicationCreateError, notificationContext, t]);

  return {
    createApplication,
    applicationCreateError,
    isApplicationCreateSuccess,
    isApplicationCreating,
    deleteApplication,
    applicationDeleteError,
    isApplicationDeleting,
    isApplicationDeleteSuccess,
  };
}

export default useApplicationEdit;
