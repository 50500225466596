import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Typography } from '@aq_mobile/ui-kit';
import { BreakPoints } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Flex, Image, Row } from 'antd';

import { ApplicationCardProps } from '@/components/ApplicationCard/ApplicationCard.types';
import { NO_ICON } from '@/constants';

const ImageStyled = styled(Image)`
  &.ant-image-img {
    border-radius: 10px;
    width: 86px;

    @media ${BreakPoints.md} {
      width: 102px;
    }
  }
`;

const FlexStyled = styled(Flex)`
  gap: 6px;

  @media ${BreakPoints.md} {
    gap: 12px;
  }
`;

/**
 * Карточка с краткой информацией о приложении
 */
function ApplicationCard({
  icon,
  name,
  categories,
  rating,
}: ApplicationCardProps) {
  const { t } = useTranslation();
  const iconSrc = icon ? icon : NO_ICON;
  const categoryTemplate = useMemo(() => {
    if (!categories?.length) {
      return null;
    }

    return (
      <div>
        {categories.map((category) => (
          <Chip type="info" key={category.id}>
            {category.name}
          </Chip>
        ))}
      </div>
    );
  }, [categories]);

  return (
    <Row align={'top'} gutter={12}>
      <Col>
        <ImageStyled src={iconSrc} preview={false} />
      </Col>
      <Col>
        <FlexStyled vertical>
          <Typography.Heading5>{name}</Typography.Heading5>
          {categoryTemplate}
          {rating && (
            <Typography.Heading6 type="secondary">
              {t('components.ApplicationCard.ageRating', { rating })}
            </Typography.Heading6>
          )}
        </FlexStyled>
      </Col>
    </Row>
  );
}

export default ApplicationCard;
