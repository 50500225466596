import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ServerError,
  useCreateAppDescriptionMutation,
  useCreateReleaseMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Набор хуков для управления релизом.
 */
function useReleaseEdit() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const [
    createRelease,
    { isLoading: isReleaseCreating, error: createReleaseError },
  ] = useCreateReleaseMutation();

  /**
   * Описание релиза так же относится к релизу.
   * Но в базе хранится как отдельная сущность, идентификатор которой привязывается к релизу.
   */
  const [
    createDescription,
    { isLoading: isDescriptionCreating, error: createDescriptionError },
  ] = useCreateAppDescriptionMutation();

  useEffect(() => {
    // Обработчик ошибки сохранения описания приложения
    if (!createDescriptionError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      createDescriptionError as unknown as ServerError,
    );
    notificationContext.showError(
      t('features.release.createDescriptionError'),
      errors,
    );
  }, [createDescriptionError, notificationContext, t]);

  useEffect(() => {
    // Обработчик ошибки сохранения релиза
    if (!createReleaseError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      createDescriptionError as unknown as ServerError,
    );
    console.error(t('features.release.createReleaseError'), createReleaseError);
    notificationContext.showError(
      t('features.release.createReleaseError'),
      errors,
    );
  }, [createReleaseError, createDescriptionError, notificationContext, t]);

  return {
    createRelease,
    isReleaseCreating,
    createReleaseError,
    createDescription,
    isDescriptionCreating,
    createDescriptionError,
  };
}

export default useReleaseEdit;
