import { ChipType } from '@aq_mobile/ui-kit';

import { ReleaseStatus } from './release.types';

/**
 * Возвращает тип компонента Chip, для сопоставления его со статусом.
 */
export function releaseStatusToChipType(status?: ReleaseStatus): ChipType {
  if (!status) {
    return 'default';
  }

  switch (status) {
    case ReleaseStatus.approved:
      return 'success';
    case ReleaseStatus.created:
      return 'default';
    case ReleaseStatus.banned:
      return 'error';
    case ReleaseStatus.onReview:
      return 'info';
    case ReleaseStatus.sentForReview:
      return 'info';
    case ReleaseStatus.published:
      return 'success';
    case ReleaseStatus.rejected:
      return 'error';
    default:
      return 'default';
  }
}

/**
 * Возвращает ключ для перевода статуса релиза
 * @param status статус релиза
 */
export function getReleaseStatusTranslationKey(status?: ReleaseStatus): string {
  switch (status) {
    case ReleaseStatus.approved:
      return 'features.release.statusApproved';
    case ReleaseStatus.created:
      return 'features.release.statusCreated';
    case ReleaseStatus.banned:
      return 'features.release.statusBanned';
    case ReleaseStatus.onReview:
      return 'features.release.statusOnReview';
    case ReleaseStatus.sentForReview:
      return 'features.release.statusSentForReview';
    case ReleaseStatus.published:
      return 'features.release.statusPublished';
    case ReleaseStatus.rejected:
      return 'features.release.statusRejected';
    default:
      return 'features.release.statusNoStatus';
  }
}
