import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload, Typography } from '@aq_mobile/ui-kit';
import { dateToLocaleString, strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import { Col, Row, Spin } from 'antd';

import { ApkField } from '@/components';
import {
  APK_MAX_SIZE_BYTE,
  APK_MAX_SIZE_MB,
  useAPK,
  useAPKEdit,
} from '@/features/apk';

import { APKProps } from './ReleaseNew.types';

/**
 * Блок загрузки APK приложения.
 */
function Apk({
  applicationId,
  value,
  appId,
  onChange,
  disabled,
  ...validationProps
}: APKProps) {
  const { t, i18n } = useTranslation();
  const { apkData: apk, isAPKLoading } = useAPK(applicationId, value);
  const { uploadAPK, isAPKUploading, isAPKDeleting } = useAPKEdit();

  const isApkPending = isAPKLoading || isAPKUploading || isAPKDeleting;

  const handleFileSelect = useCallback(
    (file: File) => {
      uploadAPK({ applicationId, file: file })
        .unwrap()
        .then((apkInfo) => onChange?.(apkInfo.id));
    },
    [applicationId, onChange, uploadAPK],
  );

  const handleFileDelete = useCallback(async () => {
    if (!applicationId || !value) {
      return;
    }

    // Файл физически не удаляется, только указатель на него из текущего релиза
    onChange?.(undefined);
  }, [applicationId, onChange, value]);

  /**
   * Отображаем кастомную ошибку об отсутствии файла.
   */
  const isNoFileError =
    validationProps['aria-required'] && validationProps['aria-invalid'];

  const releaseDate = apk
    ? dateToLocaleString(strftimeToJsDate(apk.release_date), i18n.language)
    : t('utils.NO_VALUE_SYMBOL');

  const apkName = useMemo(() => {
    if (!apk || !apk.file) {
      return null;
    }

    const lastSlashIndex = apk.file.lastIndexOf('/');
    return apk.file.substring(lastSlashIndex + 1);
  }, [apk]);

  return (
    <>
      <Spin spinning={isAPKLoading}>
        <Row gutter={22} style={{ marginBlockEnd: 64 }}>
          <Col xs={24} sm={6}>
            {t('routes.ReleaseNewApk.selectAPKLabel', {
              maxSize: APK_MAX_SIZE_MB,
            })}
          </Col>
          <Col>
            <FileUpload
              disabled={disabled}
              isPending={isApkPending}
              onSelect={handleFileSelect}
              onDelete={handleFileDelete}
              fileName={apkName}
              fileType={['apk']}
              maxSize={APK_MAX_SIZE_BYTE}
            />
            {isNoFileError && (
              <div>
                <Typography.TextS type="danger">
                  {t('routes.ReleaseNewApk.apkRequired')}
                </Typography.TextS>
              </div>
            )}
          </Col>
        </Row>

        <Row
          gutter={[{ xs: 16, sm: 32, md: 64 }, 32]}
          style={{ marginBlockEnd: 32 }}
        >
          <Col xs={24} sm={12} md={8}>
            <ApkField
              title={t('routes.ReleaseNewApk.applicationID')}
              value={appId || t('utils.NO_VALUE_SYMBOL')}
            />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <ApkField
              title={t('routes.ReleaseNewApk.applicationReleaseName')}
              value={apk?.release_name || t('utils.NO_VALUE_SYMBOL')}
            />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <ApkField
              title={t('routes.ReleaseNewApk.releaseDate')}
              value={releaseDate || t('utils.NO_VALUE_SYMBOL')}
            />
          </Col>
        </Row>
        <Row
          gutter={[{ xs: 16, sm: 32, md: 64 }, 32]}
          style={{ marginBlockEnd: 64 }}
        >
          <Col xs={24} sm={12} md={8}>
            <ApkField
              title={t('routes.ReleaseNewApk.versionCode')}
              value={apk?.version || t('utils.NO_VALUE_SYMBOL')}
            />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <ApkField
              title={t('routes.ReleaseNewApk.fileSize')}
              value={apk?.size || t('utils.NO_VALUE_SYMBOL')}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );
}

export default Apk;
