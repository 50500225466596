import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard, FileInput } from '@aq_mobile/ui-kit';
import { fileTypeToMime } from '@aq_mobile/ui-kit/utils';
import { Checkbox, Form, Input } from 'antd';

import { RELEASE_NOTES_LENGTH, useStoreAPKEdit } from '@/features/storeApk';

type NewVersionForm = {
  file?: File;
  approved?: boolean;
  optional?: boolean;
  release_notes?: string;
};

/**
 * Страница загрузки новой версии магазина приложений
 */
export default function StoreNewVersion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uploadStoreAPK, isStoreAPKUploading } = useStoreAPKEdit();
  const [form] = Form.useForm<NewVersionForm>();

  const handleFormFinish = useCallback(
    async (formValues: NewVersionForm) => {
      await uploadStoreAPK({
        file: formValues.file!,
        approved: Boolean(formValues.approved),
        optional: Boolean(formValues.optional),
        release_notes: formValues['release_notes'] as string,
      })
        .unwrap()
        .then(() => {
          navigate(`/store-updates/`);
        });
    },
    [navigate, uploadStoreAPK],
  );

  const handleCancel = useCallback(() => {
    navigate('/store-updates');
  }, [navigate]);

  return (
    <ContentCard>
      <ContentCard.Header title={t('routes.StoreNewVersion.title')} />
      <ContentCard.Body>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleFormFinish}
          disabled={isStoreAPKUploading}
          labelWrap
          colon={false}
          requiredMark={false}
          labelAlign="left"
          scrollToFirstError
        >
          <Form.Item wrapperCol={{ span: 24 }}>
            <Form.Item<NewVersionForm>
              name="approved"
              noStyle
              valuePropName="enabled"
            >
              <Checkbox
                style={{
                  marginInlineEnd: '2em',
                }}
              >
                {t('routes.StoreNewVersion.approved')}
              </Checkbox>
            </Form.Item>
            <Form.Item<NewVersionForm>
              name="optional"
              noStyle
              valuePropName="enabled"
            >
              <Checkbox>{t('routes.StoreNewVersion.optional')}</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item<NewVersionForm>
            label={t('routes.StoreNewVersion.file')}
            name="file"
            rules={[
              {
                required: true,
                message: t('routes.StoreNewVersion.fileRequired'),
              },
            ]}
          >
            <FileInput accept={fileTypeToMime(['apk']).join(',')} />
          </Form.Item>
          <Form.Item<NewVersionForm>
            label={t('routes.StoreNewVersion.releaseNotes')}
            name="release_notes"
            rules={[
              {
                required: true,
                message: t('routes.StoreNewVersion.releaseNotesRequired'),
              },
            ]}
          >
            <Input.TextArea showCount maxLength={RELEASE_NOTES_LENGTH} />
          </Form.Item>
        </Form>
      </ContentCard.Body>

      <ContentCard.Footer
        buttons={
          <>
            <Button type="default" onClick={handleCancel}>
              {t('routes.StoreNewVersion.cancel')}
            </Button>
            <Button
              type="primary"
              onClick={form.submit}
              disabled={isStoreAPKUploading}
            >
              {t('routes.StoreNewVersion.save')}
            </Button>
          </>
        }
      ></ContentCard.Footer>
    </ContentCard>
  );
}
