import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Chip, ContentCard, PaginationTotal, Table } from '@aq_mobile/ui-kit';
import { MagnifyingGlassIcon } from '@aq_mobile/ui-kit/icons';
import { dateToLocaleString, strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import { Col, Input, Row, Space, Spin, TableColumnsType } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { i18n, TFunction } from 'i18next';

import { ServerError, useGetChangelogQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { useApplication } from '@/features/application';
import { ChangelogRecord } from '@/features/changelog';
import {
  getReleaseStatusTranslationKey,
  RELEASE_HISTORY_SORT_COLUMN_SETTING,
  ReleaseStatus,
} from '@/features/release';
import filterByTerm from '@/utils/filterByTerm';
import getTableLocale from '@/utils/getTableLocale';
import { NotificationContext } from '@/utils/notification-context';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const PAGE_SIZE = 7;

function getColumnsTemplate(
  t: TFunction<'translation', undefined>,
  i18n: i18n,
): TableColumnsType<ChangelogRecord> {
  return [
    {
      title: t('routes.ApplicationHistory.columnUpdatedAt'),
      key: 'updated_at',
      dataIndex: 'updated_at',
      width: 180,
      defaultSortOrder: getSortColumnSettings(
        RELEASE_HISTORY_SORT_COLUMN_SETTING,
        'updated_at',
        'descend',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: ChangelogRecord, b: ChangelogRecord) => {
        const dateA = Number(a.updated_at);
        const dateB = Number(b.updated_at);

        return dateA - dateB;
      },
      render: (dateStr: string) => {
        if (!dateStr.length) {
          return '';
        }

        const date = strftimeToJsDate(dateStr);
        return dateToLocaleString(date, i18n.language);
      },
    },
    {
      title: t('routes.ApplicationHistory.columnUser'),
      key: 'user',
      dataIndex: 'user',
      defaultSortOrder: getSortColumnSettings(
        RELEASE_HISTORY_SORT_COLUMN_SETTING,
        'user',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.user.localeCompare(b.user),
      width: 140,
    },
    {
      title: t('routes.ApplicationHistory.columnAPKVersion'),
      key: 'apk_version',
      dataIndex: 'apk_version',
      width: 120,
    },
    {
      title: t('routes.ApplicationHistory.columnStatus'),
      key: 'state',
      dataIndex: 'state',
      width: 190,
      render: (status: ReleaseStatus) =>
        t(getReleaseStatusTranslationKey(status)),
    },
    {
      title: t('routes.ApplicationHistory.columnText'),
      key: 'text',
      dataIndex: 'text',
    },
  ];
}

export default function ApplicationHistory() {
  const { t, i18n } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const { id } = useParams();
  const application_id = id!;
  const { applicationData, isApplicationLoading } =
    useApplication(application_id);
  const {
    data: changelog,
    isLoading: isChangelogLoading,
    error: changelogError,
  } = useGetChangelogQuery({
    application: application_id,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!changelogError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      changelogError as unknown as ServerError,
    );

    notificationContext.showError(
      t('routes.ApplicationHistory.historyRequestError'),
      messages,
    );
  }, [changelogError, notificationContext, t]);

  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ChangelogRecord> | SorterResult<ChangelogRecord>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      // Сохраняем настройку сортировки таблицы
      if (['updated_at', 'user'].includes(sorter.columnKey.toString())) {
        setSortColumnSettings(
          RELEASE_HISTORY_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  const tableData = useMemo(() => {
    if (!changelog) {
      return [];
    }

    const filteredData = filterByTerm(changelog.results, searchTerm, 'text');
    return filteredData;
  }, [changelog, searchTerm]);

  const columnsTemplate = getColumnsTemplate(t, i18n);
  const tableLocale = useMemo(
    () => getTableLocale(i18n.language),
    [i18n.language],
  );

  return (
    <ContentCard>
      <ContentCard.Header
        title={t('routes.ApplicationHistory.title')}
        chip={
          <Spin spinning={isApplicationLoading}>
            <Chip type="info">{applicationData?.name}</Chip>
          </Spin>
        }
      ></ContentCard.Header>

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('routes.ApplicationHistory.searchPlaceholder')}
                suffix={<MagnifyingGlassIcon />}
                allowClear
              />
            </Space>
          </Col>
          <Col>
            <PaginationTotal
              currentPage={currentPage}
              pageSize={PAGE_SIZE}
              total={tableData.length}
            />
          </Col>
        </Row>

        <Spin spinning={isChangelogLoading}>
          <Table
            dataSource={tableData}
            columns={columnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
            onChange={handleTableChange}
            locale={tableLocale}
            scroll={{ x: 768 }}
          ></Table>
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
