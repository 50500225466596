import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ServerError,
  useApproveReleaseMutation,
  useBanReleaseMutation,
  useEditReleaseStateMutation,
  usePublishReleaseMutation,
  useRejectReleaseMutation,
  useSendForReviewReleaseMutation,
  useTakeForReviewReleaseMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук, для изменения состояния (статуса) релиза.
 */
function useModifyReleaseState() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const [
    approve,
    {
      isLoading: isApproveLoading,
      isError: isApproveError,
      error: approveError,
    },
  ] = useApproveReleaseMutation();
  const [
    ban,
    { isLoading: isBanLoading, isError: isBanError, error: banError },
  ] = useBanReleaseMutation();
  const [
    publish,
    {
      isLoading: isPublishLoading,
      isError: isPublishError,
      error: publishError,
    },
  ] = usePublishReleaseMutation();
  const [
    reject,
    { isLoading: isRejectLoading, isError: isRejectError, error: rejectError },
  ] = useRejectReleaseMutation();
  const [
    sendForReview,
    {
      isLoading: isSendForReviewLoading,
      isError: isSendForReviewError,
      error: sendForReviewError,
    },
  ] = useSendForReviewReleaseMutation();
  const [
    takeForReview,
    {
      isLoading: isTakeForReviewLoading,
      isError: isTakeForReviewError,
      error: takeForReviewError,
    },
  ] = useTakeForReviewReleaseMutation();
  const [
    editReleaseState,
    {
      isLoading: isEditReleaseStateLoading,
      isError: isEditReleaseStateError,
      error: editReleaseStateError,
    },
  ] = useEditReleaseStateMutation();

  useEffect(() => {
    if (!approveError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      approveError as unknown as ServerError,
    );

    notificationContext.showError(
      t('features.release.getReleaseError'),
      messages,
    );
    console.error(t('features.release.approveError'), approveError);
  }, [approveError, notificationContext, t]);

  useEffect(() => {
    if (!banError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      banError as unknown as ServerError,
    );

    notificationContext.showError(t('features.release.banError'), messages);
    console.error(t('features.release.banError'), banError);
  }, [banError, notificationContext, t]);

  useEffect(() => {
    if (!publishError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      publishError as unknown as ServerError,
    );

    notificationContext.showError(t('features.release.publishError'), messages);
    console.error(t('features.release.publishError'), publishError);
  }, [publishError, notificationContext, t]);

  useEffect(() => {
    if (!rejectError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      rejectError as unknown as ServerError,
    );

    notificationContext.showError(t('features.release.rejectError'), messages);
    console.error(t('features.release.rejectError'), rejectError);
  }, [rejectError, notificationContext, t]);

  useEffect(() => {
    if (!sendForReviewError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      sendForReviewError as unknown as ServerError,
    );

    notificationContext.showError(
      t('features.release.sendForReviewError'),
      messages,
    );
    console.error(t('features.release.sendForReviewError'), sendForReviewError);
  }, [sendForReviewError, notificationContext, t]);

  useEffect(() => {
    if (!takeForReviewError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      takeForReviewError as unknown as ServerError,
    );

    notificationContext.showError(
      t('features.release.takeForReviewError'),
      messages,
    );
    console.error(t('features.release.takeForReviewError'), takeForReviewError);
  }, [takeForReviewError, notificationContext, t]);

  useEffect(() => {
    if (!editReleaseStateError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      editReleaseStateError as unknown as ServerError,
    );

    notificationContext.showError(
      t('features.release.editReleaseStateError'),
      messages,
    );
    console.error(
      t('features.release.editReleaseStateError'),
      editReleaseStateError,
    );
  }, [editReleaseStateError, notificationContext, t]);

  return {
    approve,
    isApproveLoading,
    isApproveError,
    approveError,
    ban,
    isBanLoading,
    isBanError,
    banError,
    publish,
    isPublishLoading,
    isPublishError,
    publishError,
    reject,
    isRejectLoading,
    isRejectError,
    rejectError,
    sendForReview,
    isSendForReviewLoading,
    isSendForReviewError,
    sendForReviewError,
    takeForReview,
    isTakeForReviewLoading,
    isTakeForReviewError,
    takeForReviewError,
    editReleaseState,
    isEditReleaseStateLoading,
    isEditReleaseStateError,
    editReleaseStateError,
  };
}

export default useModifyReleaseState;
