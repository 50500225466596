import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Chip,
  ContentCard,
  Dropdown,
  type DropDownItemStringKey,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from '@aq_mobile/ui-kit/icons';
import { dateToLocaleString, strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import {
  Avatar,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  TableColumnsType,
} from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { i18n, TFunction } from 'i18next';

import { NO_ICON } from '@/constants';
import { ApplicationsRecord } from '@/features/api';
import {
  ApplicationInfo,
  APPLICATIONS_SORT_COLUMN_SETTING,
  useApplicationEdit,
  useApplications,
} from '@/features/application';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import usePermission from '@/features/permissions/hooks/usePermission';
import {
  getReleaseStatusTranslationKey,
  ReleaseStatus,
  releaseStatusToChipType,
  useReleaseStateDropdownItems,
} from '@/features/release';
import { settingsActions, settingsSelectors } from '@/features/settings';
import { rootSelectors } from '@/features/store';
import filterByTerm from '@/utils/filterByTerm';
import getTableLocale from '@/utils/getTableLocale';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

function getColumnsTemplate(
  t: TFunction<'translation', undefined>,
  i18n: i18n,
): TableColumnsType<ApplicationsRecord> {
  return [
    {
      key: 'icon',
      dataIndex: ['published_release', 'icon'],
      render: (iconUrl) => {
        const icon = iconUrl ?? NO_ICON;
        return (
          <Avatar
            size="small"
            src={icon}
            shape="square"
            style={{ borderWidth: 0 }}
          />
        );
      },
      width: 50,
    },
    {
      key: 'name',
      title: t('routes.Applications.columnName'),
      dataIndex: 'name',
      defaultSortOrder: getSortColumnSettings(
        APPLICATIONS_SORT_COLUMN_SETTING,
        'name',
        'ascend',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      key: 'version',
      title: t('routes.Applications.columnVersion'),
      dataIndex: ['published_release', 'release_name'],
      responsive: ['md'],
    },
    {
      key: 'developer',
      title: t('routes.Applications.columnDeveloper'),
      dataIndex: 'developer',
    },
    {
      key: 'release_date',
      title: t('routes.Applications.columnReleaseDate'),
      dataIndex: ['published_release', 'release_date'],
      render: (date?: string) => {
        if (!date) {
          return null;
        }

        return dateToLocaleString(strftimeToJsDate(date), i18n.language);
      },
      defaultSortOrder: getSortColumnSettings(
        APPLICATIONS_SORT_COLUMN_SETTING,
        'release_date',
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => {
        const dateA = Boolean(a.published_release?.release_date)
          ? Number(a.published_release?.release_date)
          : 0;
        const dateB = Boolean(b.published_release?.release_date)
          ? Number(b.published_release?.release_date)
          : 0;

        return dateA - dateB;
      },
    },
    {
      key: 'latest_release_state',
      title: t('routes.Applications.columnLatestReleaseStatus'),
      dataIndex: 'latest_release_state',
      render: (status: ReleaseStatus) => {
        if (!status) {
          return (
            <Chip type="default">{t('routes.Applications.noRelease')}</Chip>
          );
        }

        return (
          <Chip type={releaseStatusToChipType(status)}>
            {t(getReleaseStatusTranslationKey(status))}
          </Chip>
        );
      },
      ellipsis: true,
      width: 220,
    },
  ];
}

const developerColumns = [
  'icon',
  'name',
  'version',
  'release_date',
  'latest_release_state',
];

const adminColumns = [
  'icon',
  'name',
  'version',
  'developer',
  'latest_release_state',
];

const PAGE_SIZE = 8;

/**
 * Страница со списком приложений
 */
export default function Applications() {
  const { t, i18n } = useTranslation();
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { isApplicationsLoading, applications } = useApplications();
  const { deleteApplication, isApplicationDeleting } = useApplicationEdit();
  const userRole = useAppSelector(rootSelectors.users.userRoleSelector);
  const statusFilterItems = useReleaseStateDropdownItems(true);

  const navigate = useNavigate();
  const isDeleteAllowed = usePermission('applicationDelete');
  const isAddAllowed = usePermission('applicationAdd');

  const [searchTerm, setSearchTerm] = useState<string>('');

  const { key: selectedFilterKey } = useAppSelector(
    settingsSelectors.selectApplicationsStatusFilter,
  );

  const statusFilter = useMemo(() => {
    return (
      statusFilterItems.find(
        (item) => item.key === (selectedFilterKey as string),
      ) || statusFilterItems[statusFilterItems.length - 1]
    );
  }, [statusFilterItems, selectedFilterKey]);

  const dispatch = useAppDispatch();

  /** Элемент фильтра для приложений с отсутствующим релизом */
  const noReleaseFilterItem = useMemo(
    () => ({
      key: 'no-release',
      label: t('routes.Applications.noRelease'),
    }),
    [t],
  );

  const rowSelection = useMemo(() => {
    // Если rowSelection = undefined скрывается возможность удалять записи.
    if (!isDeleteAllowed) {
      return undefined;
    }

    return { selectedRowKes: selectedRowKeys, onChange: setSelectedRowKeys };
  }, [isDeleteAllowed, selectedRowKeys]);

  const handleDelete = useCallback(() => {
    selectedRowKeys.forEach((appId) => {
      deleteApplication(appId.toString());
    });

    setSelectedRowKeys([]);
  }, [deleteApplication, selectedRowKeys]);

  const handleGoToApp = useCallback(
    ({ id }: ApplicationInfo) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  const handleNewApp = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const setStatusFilter = useCallback(
    (value: { key: string; label: string }) => {
      dispatch(
        settingsActions.setApplicationsStatusFilter({
          key: value.key,
        }),
      );
    },
    [dispatch],
  );

  const tableColumnsTemplate = useMemo(() => {
    const allColumns = getColumnsTemplate(t, i18n);

    const filteredColumns = allColumns.filter((column) => {
      if (!column.key) {
        return false;
      }

      if (userRole === 'admin') {
        return adminColumns.includes(column.key.toString());
      }

      return developerColumns.includes(column.key.toString());
    });

    return filteredColumns;
  }, [userRole, t]);

  const tableData = useMemo(() => {
    if (!applications.length) {
      return [];
    }

    const filteredData = applications.filter(({ latest_release_state }) => {
      const isStatusFilterNotSet = selectedFilterKey === 'all';
      if (isStatusFilterNotSet) {
        return true;
      }

      if (
        selectedFilterKey === noReleaseFilterItem.key &&
        latest_release_state === null
      ) {
        return true;
      }

      const releaseStateFilter = selectedFilterKey as ReleaseStatus;

      return latest_release_state === releaseStateFilter;
    });

    const nameFiltered = filterByTerm(filteredData, searchTerm, 'name');

    return nameFiltered;
  }, [applications, noReleaseFilterItem.key, searchTerm, selectedFilterKey]);

  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter:
        | SorterResult<ApplicationsRecord>
        | SorterResult<ApplicationsRecord>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      if (['name', 'release_date'].includes(sorter.columnKey.toString())) {
        setSortColumnSettings(
          APPLICATIONS_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  const isApplicationsInProgress =
    isApplicationsLoading || isApplicationDeleting;

  const AddButtonTemplate = useMemo(
    () =>
      isAddAllowed ? (
        <Button type="primary" icon={<PlusIcon />} onClick={handleNewApp}>
          {t('routes.Applications.add')}
        </Button>
      ) : undefined,
    [handleNewApp, isAddAllowed, t],
  );

  const TableControlsTemplate = useMemo(() => {
    const SearchField = (
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={t('routes.Applications.search')}
        suffix={<MagnifyingGlassIcon />}
        allowClear
      />
    );

    const DeleteButton =
      isDeleteAllowed && Boolean(selectedRowKeys.length) ? (
        <Popconfirm
          title={t('routes.Applications.confirmDeletionTitle')}
          description={
            selectedRowKeys.length > 1
              ? t('routes.Applications.confirmDeletionQuestionMultiple')
              : t('routes.Applications.confirmDeletionQuestionSingle')
          }
          okText={t('routes.Applications.confirm')}
          cancelText={t('routes.Applications.cancel')}
          onConfirm={handleDelete}
        >
          <Button
            icon={<TrashIcon />}
            type="primary"
            title={t('routes.Applications.deleteSelected')}
          />
        </Popconfirm>
      ) : undefined;

    return (
      <Space wrap>
        {DeleteButton}
        {SearchField}
      </Space>
    );
  }, [handleDelete, isDeleteAllowed, searchTerm, selectedRowKeys.length, t]);

  const tableLocale = useMemo(
    () => getTableLocale(i18n.language),
    [i18n.language],
  );

  // Набор значений для фильтра, с дополнительным значением "Релиз отсутствует"
  const stateFilterItems = useMemo(() => {
    return [noReleaseFilterItem, ...statusFilterItems];
  }, [noReleaseFilterItem, statusFilterItems]);

  return (
    <ContentCard>
      <ContentCard.Header
        title={t('routes.Applications.title')}
        buttons={AddButtonTemplate}
      />

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col>{TableControlsTemplate}</Col>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              <Typography.TextS>
                {t('routes.Applications.statusFilter')}
              </Typography.TextS>
              <Dropdown<DropDownItemStringKey>
                items={stateFilterItems}
                selectedText={statusFilter.label}
                onSelect={setStatusFilter}
              />
            </Space>
          </Col>
          <Col>
            <PaginationTotal
              currentPage={currentTablePage}
              pageSize={PAGE_SIZE}
              total={tableData.length || 0}
            />
          </Col>
        </Row>

        <Spin spinning={isApplicationsInProgress}>
          <TableStyled
            rowSelection={rowSelection}
            dataSource={tableData}
            columns={tableColumnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentTablePage(page);
              },
            }}
            onChange={handleTableChange}
            locale={tableLocale}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  handleGoToApp(record);
                },
              };
            }}
            scroll={{ x: 768 }}
          />
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
