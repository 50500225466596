import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageUpload, ImageUploadErrorWrapper } from '@aq_mobile/ui-kit';

import {
  ServerError,
  useGetIconQuery,
  useUploadIconMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

import { AppIconProps } from './ReleaseNew.types';

/** Раздел иконки приложения */
function AppIcon({ applicationId, value, onChange, disabled }: AppIconProps) {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const {
    data,
    isLoading,
    error: getIconError,
  } = useGetIconQuery(
    { application_id: applicationId, id: value },
    { skip: value === undefined },
  );

  const [uploadIcon, { isLoading: isUploading, error: uploadingError }] =
    useUploadIconMutation();

  useEffect(() => {
    if (!uploadingError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      uploadingError as unknown as ServerError,
    );

    notificationContext.showError(
      t('routes.ReleaseNewIcon.iconUploadError'),
      errors,
    );
  }, [uploadingError, notificationContext, t]);

  useEffect(() => {
    if (!getIconError) {
      return;
    }

    notificationContext.showError(t('routes.ReleaseNewIcon.iconDownloadError'));
  }, [getIconError, notificationContext, t]);

  const handleIconSelect = useCallback(
    async (file: File) => {
      const result = await uploadIcon({
        application_id: applicationId,
        file: file,
      }).unwrap();

      onChange?.(result.id);
    },
    [applicationId, uploadIcon, onChange],
  );

  const handleIconDelete = useCallback(() => {
    // Иконка удаляется только локально. Сервер сможет удалить иконку
    // тогда, когда она не будет присоединена ни к одному релизу.
    onChange?.(undefined);
  }, [onChange]);

  const isIconPending = isLoading || isUploading;

  const iconValue = useMemo(() => {
    if (!value) {
      return undefined;
    }

    return data?.file ? [data?.file] : undefined;
  }, [data, value]);

  return (
    <ImageUploadErrorWrapper
      errorText={t('routes.ReleaseNewIcon.iconRequired')}
    >
      <ImageUpload
        maxCount={1}
        fileType={['png', 'jpg']}
        maxResolution={[512, 512]}
        maxSize={1024 * 1024 * 8}
        value={iconValue}
        onDelete={handleIconDelete}
        onSelect={handleIconSelect}
        isLoading={isIconPending}
        disabled={disabled}
      />
    </ImageUploadErrorWrapper>
  );
}

export default AppIcon;
