import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCategoriesQuery } from '@/features/api';
import { Category } from '@/features/category';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для работы с категориями.
 */
function useCategories() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isCategoriesLoading,
    data,
    error: categoriesError,
  } = useGetCategoriesQuery();

  const categories = (data?.results as Array<Category>) || [];

  useEffect(() => {
    if (!categoriesError) {
      return;
    }

    notificationContext.showError(
      t('utils.error'),
      t('features.category.getCategoriesError'),
    );
  }, [categoriesError, notificationContext, t]);

  return {
    isCategoriesLoading,
    categories,
    categoriesError,
  };
}

export default useCategories;
