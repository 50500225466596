import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetNotificationsQuery } from '@/features/api';
import { useAppSelector } from '@/features/hooks';
import { rootSelectors } from '@/features/store';
import { NotificationContext } from '@/utils/notification-context';

const DEFAULT_NOTIFICATIONS_LIMIT = 100;
const NOTIFICATIONS_POLLING_INTERVAL = 60000;

/** Функции для получения списка уведомлений */
function useNotifications() {
  const { t } = useTranslation();
  // На данный момент для модератора и для администратора нет уведомлений.
  const role = useAppSelector(rootSelectors.users.userRoleSelector);
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isNotificationsLoading,
    data,
    error: getNotificationsError,
  } = useGetNotificationsQuery(
    {
      limit: DEFAULT_NOTIFICATIONS_LIMIT,
    },
    {
      pollingInterval: NOTIFICATIONS_POLLING_INTERVAL,
      skip: !['developer'].includes(role),
    },
  );

  const notifications = useMemo(
    () => data?.results.filter((record) => !record.read) || [],
    [data],
  );

  useEffect(() => {
    if (!getNotificationsError) {
      return;
    }

    notificationContext.showError(
      t('utils.error'),
      t('features.notifications.getNotificationsError'),
    );
  }, [getNotificationsError, notificationContext, t]);

  return {
    isNotificationsLoading,
    notifications,
    getNotificationsError,
  };
}

export default useNotifications;
