import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  CommentModal,
  ContentCard,
  Typography,
} from '@aq_mobile/ui-kit';
import { Form, Input, Select, Spin } from 'antd';

import { NavigationBlocker, ScreenshotsInput } from '@/components';
import { CreateReleaseRequest } from '@/features/api';
import { RELEASE_NOTES_LENGTH } from '@/features/apk';
import { useApplication } from '@/features/application';
import { useCategories } from '@/features/category';
import { useDeviceGroups } from '@/features/deviceGroup';
import {
  getReleaseStatusTranslationKey,
  ReleaseStatus,
  releaseStatusToChipType,
  useEditingRelease,
  useModifyReleaseState,
  useReleaseEdit,
} from '@/features/release';
import {
  Screenshot,
  screenshotMaxCountRule,
  screenshotMinCountRule,
} from '@/features/screenshots';
import { ReleaseForm } from '@/routes/ReleaseNew/ReleaseNew.types';
import { checkIsDescriptionChanged } from '@/routes/ReleaseNew/ReleaseNew.utils';
import { NotificationContext } from '@/utils/notification-context';

import ReleaseNewApk from './ReleaseNewApk';
import ReleaseNewDescription from './ReleaseNewDescription';
import ReleaseNewIcon from './ReleaseNewIcon';

/**
 * Страница создания релиза приложения
 */
function ReleaseNew() {
  const { t } = useTranslation();
  const { id } = useParams();
  const application_id = id!;
  const navigate = useNavigate();
  const {
    editingRelease,
    error: editingReleaseError,
    isLoading: isEditingReleaseLoading,
    isSuccess: isEditingReleaseSuccess,
  } = useEditingRelease(application_id);
  const {
    deviceGroups,
    isLoading: isDeviceGroupLoading,
    error: deviceGroupError,
  } = useDeviceGroups();
  const notificationContext = useContext(NotificationContext);
  const { isApplicationLoading, applicationData, applicationError } =
    useApplication(application_id);
  const { categories, isCategoriesLoading, categoriesError } = useCategories();
  const {
    createRelease,
    createDescription,
    isReleaseCreating,
    isDescriptionCreating,
  } = useReleaseEdit();
  const { sendForReview, sendForReviewError, isSendForReviewLoading } =
    useModifyReleaseState();
  const [formInitialValues, setFormInitialValues] = useState<ReleaseForm>();
  const [form] = Form.useForm<ReleaseForm>();
  /** Признак того, что форма была изменена и необходимо выдавать предупреждение
   * при попытке уйти с формы без сохранения изменений */
  const [isFormTouched, setIsFormTouched] = useState(false);
  // Комментарий для отправки релиза на модерацию
  const [reviewComment, setReviewComment] = useState<string>();
  const [isSendForReview, setIsSendForReview] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const statusChipProps = useMemo(() => {
    const state = editingRelease?.releasestate.state;
    // Когда статус отсутствует, это означает, что пользователь делает самый первый релиз
    // Если статус "Опубликовано" или "Запрещен", это означает, что пользователь создает
    // новый релиз, на основе опубликованного, а не редактирует отклоненный или ранее созданный.
    const isNewState =
      !state || [ReleaseStatus.published, ReleaseStatus.banned].includes(state);
    const type = isNewState ? 'default' : releaseStatusToChipType(state);
    const label = isNewState
      ? t('routes.ReleaseNew.newLabel')
      : getReleaseStatusTranslationKey(state);

    return { type, label };
  }, [editingRelease?.releasestate.state, t]);

  useEffect(() => {
    // Заполняем изначальные значения на форме.
    if (!isEditingReleaseSuccess) {
      return;
    }

    // Если отсутствует релиз, на основе которого будет создаваться текущий
    // просто проставляем изначальные значения и сбрасываем форму.
    if (!editingRelease) {
      setFormInitialValues({
        category: [],
        description: {
          rating: '0+',
        },
      });
      setTimeout(form.resetFields, 0);
      return;
    }

    const phoneScreenshots: Array<Screenshot> = [];
    const tabletScreenshots: Array<Screenshot> = [];

    editingRelease.screenshots.forEach((screenshot) => {
      if (screenshot.device_type === 'phone') {
        phoneScreenshots.push(screenshot);
        return;
      }

      tabletScreenshots.push(screenshot);
    });

    const deviceGroup =
      editingRelease.description.device_group?.map((group) => group.id) || [];

    setFormInitialValues({
      apk: editingRelease.apk.id,
      icon: editingRelease.icon.id,
      phoneScreenshots: phoneScreenshots,
      tabletScreenshots: tabletScreenshots,
      release_notes: editingRelease.release_notes,
      description: {
        description: editingRelease.description.description,
        device_group: deviceGroup,
        rating: editingRelease.description.rating,
      },
      category: editingRelease.category,
    });

    // Hack: планируем сброс формы в следующем цикле event loop-а
    // иначе изначальные значения не будут проставлены.
    // Установка изначальных значений отмечает форму, как.
    setTimeout(form.resetFields, 0);
  }, [application_id, form, editingRelease, isEditingReleaseSuccess]);

  useEffect(() => {
    // Обработчик ошибок запросов.
    if (
      !applicationError &&
      !categoriesError &&
      !sendForReviewError &&
      !editingReleaseError &&
      !deviceGroupError
    ) {
      return;
    }

    navigate('..');
  }, [
    applicationError,
    categoriesError,
    deviceGroupError,
    editingReleaseError,
    navigate,
    sendForReviewError,
  ]);

  const handleSendForReview = useCallback(
    async (releaseId: number) => {
      try {
        // Если необходимо отправить сохраненный релиз на модерацию.
        const result = await sendForReview({
          application_id,
          id: releaseId,
          text: reviewComment,
        }).unwrap();

        notificationContext.showSuccess(t('routes.ReleaseNew.sentOnReview'));
        setIsSendForReview(false);
        setReviewComment(undefined);
        return result;
      } catch (e) {
        //
      }
    },
    [application_id, notificationContext, reviewComment, sendForReview, t],
  );

  const handleSaveRelease = useCallback(
    async (release: CreateReleaseRequest) => {
      const releaseResult = await createRelease(release).unwrap();

      notificationContext.showSuccess(t('routes.ReleaseNew.releaseSaved'));
      return releaseResult;
    },
    [createRelease, notificationContext, t],
  );

  const handleFormFinish = useCallback(
    async (formValues: ReleaseForm) => {
      // Если форма не редактировалась и релиз не нужно отправлять на модерацию, то
      // просто отображаем сообщение.
      if (!isFormTouched && !isSendForReview) {
        notificationContext.showWarning(
          t('routes.ReleaseNew.releaseNotEdited'),
          t('routes.ReleaseNew.noChanges'),
        );
        return;
      }

      // Если релиз необходимо отправить на модерацию.
      // Повторно или в первый раз, необходимо выполнить сохранение
      // данных релиза под новым идентификатором

      let screenshots: Array<number> = [];

      if (formValues.phoneScreenshots?.length) {
        screenshots = formValues.phoneScreenshots
          ? formValues.phoneScreenshots.map((screenshot) => screenshot.id)
          : [];
      }

      if (formValues.tabletScreenshots?.length) {
        screenshots = [
          ...screenshots,
          ...formValues.tabletScreenshots.map((screenshot) => screenshot.id),
        ];
      }

      // Если описание приложения еще не было создано или отличается от сохраненного
      // сначала сохраняем описание, чтобы получить его идентификатор
      // т.к. это отдельная сущность
      const previousDescription = editingRelease?.description;
      const isDescriptionChanged = checkIsDescriptionChanged(
        previousDescription,
        formValues.description,
      );
      let descriptionId = undefined;

      try {
        if (!previousDescription || isDescriptionChanged) {
          const description = await createDescription({
            application_id,
            description: formValues.description.description!,
            rating: formValues.description.rating,
            device_group: formValues.description.device_group,
          }).unwrap();

          descriptionId = description.id;
        } else {
          descriptionId = previousDescription.id;
        }

        const releaseData = {
          application_id,
          apk: formValues.apk!,
          release_notes: formValues.release_notes,
          description: descriptionId,
          icon: formValues.icon!,
          category: formValues.category,
          screenshots,
        };

        const updatedRelease = await handleSaveRelease(releaseData);

        // Если после сохранения релиза нужно отправить его на модерацию.
        if (isSendForReview) {
          await handleSendForReview(updatedRelease.id);
        }
      } catch {
        setIsSendForReview(false);
        setReviewComment(undefined);
      } finally {
        setIsFormTouched(false);
        // Hack: для осуществления автоматической навигации, мы должны
        // сначала сбросить флаг, запрещающий переход. А потом выполнить переход.
        // Флаг сбрасывается асинхронно, заменой переменной состояния. Поэтому нужна задержка.
        setTimeout(() => navigate('..'), 100);
      }
    },
    [
      application_id,
      createDescription,
      editingRelease?.description,
      handleSaveRelease,
      handleSendForReview,
      isFormTouched,
      isSendForReview,
      navigate,
      notificationContext,
      t,
    ],
  );

  // Обработчик сохранения формы и отправки на ревью
  const handleSaveAndSendOnReview = useCallback(async () => {
    /**
     * Алгоритм:
     * 1. На модерацию нельзя отправить уже отправленный на модерацию релиз.
     * 2. Проверяем валидность формы
     * 3.а. Форма не валидна. Отображаем предупреждение об ошибках.
     * 3.б. Форма валидна. Отображаем модальное окно комментария.
     * 4. Если в модальном окне нажали Ок, отмечаем необходимость отправить релиз на модерацию
     * сразу же, после сохранения формы.
     *  */

    if (editingRelease?.releasestate.state === ReleaseStatus.sentForReview) {
      notificationContext.showWarning(
        t('routes.ReleaseNew.alreadySentOnReview'),
      );

      return;
    }

    try {
      // Проверяем валидность формы.
      await form.validateFields();
      // Если форма валидна, показываем модальное окно с комментарием для модерации
      // Нажатие Ok в модальном окне вызовет сохранение релиза.
      setIsCommentModalOpen(true);
    } catch (e) {
      notificationContext.showWarning(
        t('routes.ReleaseNew.requiredFieldsNotFilled'),
        t('routes.ReleaseNew.formIncomplete'),
      );
    }
  }, [editingRelease?.releasestate.state, form, notificationContext, t]);

  /** Отслеживает изменения на форме и проставляет признак изменения, если изменения произошли.
   * Это позволяет предупредить пользователя, о том, что он может потерять данные, если закроет форму.
   * Изменения на форме не вызывают перерисовки шаблона, поэтому иначе узнать о них не получается.
   */
  const handleValueChanges = useCallback(() => {
    const isTouched = form.isFieldsTouched();
    if (isFormTouched === isTouched) {
      return;
    }

    setIsFormTouched(isTouched);
  }, [form, isFormTouched]);

  // Обработчик сохранения формы по нажатию кнопки "Сохранить"
  const handleSave = useCallback(() => {
    // Сбрасываем отметку о том, что релиз необходимо отправить на модерацию.
    // Это нужно, если при попытке со
    setIsSendForReview(false);
    form.submit();
  }, [form]);

  /* Обработчик нажатия кнопки Ok, в модальном окне комментария модератору */
  const handleModalOk = useCallback(
    async (comment: string | undefined) => {
      setIsSendForReview(true);
      setReviewComment(comment);
      setIsCommentModalOpen(false);

      form.submit();
    },
    [form],
  );

  /* Отменяет отправку релиза на модерацию */
  const handleModalCancel = useCallback(() => {
    setIsCommentModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    setIsFormTouched(false);
    setTimeout(() => navigate(`/apps/${application_id}/releases`), 100);
  }, [application_id, navigate]);

  const isFormLocked =
    isApplicationLoading ||
    isCategoriesLoading ||
    isReleaseCreating ||
    isDescriptionCreating ||
    isEditingReleaseLoading ||
    isDeviceGroupLoading ||
    isSendForReviewLoading;

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title={t('routes.ReleaseNew.title')}
          chip={
            <Spin spinning={isApplicationLoading}>
              <Chip type="info">{applicationData?.name}</Chip>
              <Chip type={statusChipProps.type}>{statusChipProps.label}</Chip>
            </Spin>
          }
        />
        <ContentCard.Body>
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleFormFinish}
            initialValues={formInitialValues}
            labelWrap
            colon={false}
            requiredMark={false}
            labelAlign="left"
            disabled={isFormLocked}
            scrollToFirstError
            onValuesChange={handleValueChanges}
          >
            <Form.Item wrapperCol={{ span: 24 }}>
              <Form.Item
                noStyle
                name="apk"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <ReleaseNewApk
                  applicationId={application_id}
                  appId={applicationData?.app_id}
                  disabled={isFormLocked}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item<ReleaseForm>
              label={t('routes.ReleaseNew.releaseNotes')}
              name="release_notes"
              rules={[
                {
                  required: true,
                  message: t('routes.ReleaseNew.releaseNotesRequired'),
                },
              ]}
            >
              <Input.TextArea showCount maxLength={RELEASE_NOTES_LENGTH} />
            </Form.Item>

            <Typography.Heading5
              style={{
                marginBlockStart: 64,
                marginBlockEnd: 48,
              }}
            >
              Информация о приложении
            </Typography.Heading5>

            <Form.Item<ReleaseForm>
              label={t('routes.ReleaseNew.category')}
              name="category"
              rules={[
                {
                  required: true,
                  message: t('routes.ReleaseNew.categoryRequired'),
                },
              ]}
            >
              <Select
                options={categories}
                mode="multiple"
                autoClearSearchValue
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
              />
            </Form.Item>

            <ReleaseNewDescription deviceGroups={deviceGroups?.results} />

            <Typography.Heading5
              style={{
                marginBlockStart: 64,
                marginBlockEnd: 48,
              }}
            >
              {t('routes.ReleaseNew.graphicsSection')}
            </Typography.Heading5>
            <Form.Item<ReleaseForm>
              label={t('routes.ReleaseNew.applicationIcon')}
              name="icon"
              rules={[
                {
                  required: true,
                  message: t('routes.ReleaseNew.applicationIconRequired'),
                },
              ]}
            >
              <ReleaseNewIcon applicationId={application_id} />
            </Form.Item>

            <Form.Item<ReleaseForm>
              label={t('routes.ReleaseNew.phoneScreenshots')}
              name="phoneScreenshots"
              rules={[
                {
                  required: true,
                  message: t('routes.ReleaseNew.phoneScreenshotsRequired'),
                },
                screenshotMinCountRule,
                screenshotMaxCountRule,
              ]}
            >
              <ScreenshotsInput
                applicationId={application_id}
                deviceType="phone"
              />
            </Form.Item>

            <Form.Item<ReleaseForm>
              label={t('routes.ReleaseNew.tabletScreenshots')}
              name="tabletScreenshots"
              rules={[
                {
                  required: true,
                  message: t('routes.ReleaseNew.tabletScreenshotsRequired'),
                },
                screenshotMinCountRule,
                screenshotMaxCountRule,
              ]}
            >
              <ScreenshotsInput
                applicationId={application_id}
                deviceType="tablet"
              />
            </Form.Item>
          </Form>
        </ContentCard.Body>
        <ContentCard.Footer
          buttons={
            <>
              <Button
                type="primary"
                onClick={handleSave}
                disabled={isFormLocked}
              >
                {t('routes.ReleaseNew.save')}
              </Button>

              <Button
                type="default"
                onClick={handleSaveAndSendOnReview}
                disabled={isFormLocked}
              >
                {t('routes.ReleaseNew.sendOnReview')}
              </Button>

              <Button type="text" danger onClick={handleCancel}>
                {t('routes.ReleaseNew.cancel')}
              </Button>
            </>
          }
        ></ContentCard.Footer>
      </ContentCard>

      <CommentModal
        title={t('routes.ReleaseNew.commentModalTitle')}
        label={t('routes.ReleaseNew.commentModalLabel')}
        open={isCommentModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      />

      <NavigationBlocker shouldBlock={isFormTouched} />
    </>
  );
}

export default ReleaseNew;
