import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Modal, Typography } from '@aq_mobile/ui-kit';
import { Space } from 'antd';

import {
  ReleaseStatus,
  useReleaseStateDropdownItems,
  useReleaseStatusName,
} from '@/features/release';

import { ReleaseStateModalProps } from './ReleaseStateModal.types';

/**
 * Модальное окно для смены статуса релиза
 */
function ReleaseStateModal({
  selectedState,
  onOk,
  open,
  onOpenChange,
}: ReleaseStateModalProps) {
  const { t } = useTranslation();
  const statusesWithoutAllItem = useReleaseStateDropdownItems(false);
  /** Переменная используется для хранения выбираемого значения статуса в выпадающем меню */
  const [internalStateValue, setInternalStateValue] =
    useState<ReleaseStatus>(selectedState);
  const statusLabel = useReleaseStatusName(internalStateValue);

  useEffect(() => {
    setInternalStateValue(selectedState);
  }, [selectedState]);

  const possibleStatuses = useMemo(() => {
    // Если релиз не находится в статусе "Опубликован", ему нельзя поставить статус "Запрещен".
    // Иначе можно получить ситуацию, когда у нас есть один "опубликованный" и один "запрещенный" релиз.
    // Такого быть не должно, т.к. "Запрещен" должен снимать релиз из публикации в магазине.
    if (selectedState !== ReleaseStatus.published) {
      return statusesWithoutAllItem.filter(
        (item) => (item.key as ReleaseStatus) !== ReleaseStatus.banned,
      );
    }

    return statusesWithoutAllItem;
  }, [selectedState, statusesWithoutAllItem]);

  const handleStatusSelect = useCallback(
    (status: { key: string; label: string }) =>
      setInternalStateValue(status.key as ReleaseStatus),
    [],
  );

  return (
    <Modal
      title={t('components.ReleaseStateModal.title')}
      open={open}
      okText={t('components.ReleaseStateModal.ok')}
      cancelText={t('components.ReleaseStateModal.cancel')}
      onOk={() => {
        onOpenChange?.(false);
        onOk?.(internalStateValue);
      }}
      onCancel={() => {
        /* Возвращаем значение статуса обратно на статус, с которым это окно было вызвано.
         * Обрабатывает случай:
         * 1. Пользователь открыл диалог изменения статуса у релиза.
         * 2. Выбрал статус из списка.
         * 3. Передумал менять статус, нажал "Отмена".
         * 4. Опять решил изменить статус того же релиза.
         * В этом случае должен корректно установиться текущий статус релиза.
         * Если сброс не сделать, то selectedState между вызовами не менялся, а соответственно
         * выбранным статусом будет статус, который пользователь выбрал на шаге 2.
         */

        if (internalStateValue !== selectedState) {
          setInternalStateValue(selectedState);
        }

        onOpenChange?.(false);
      }}
    >
      <Space>
        <Typography.TextS>Статус релиза</Typography.TextS>
        <Dropdown
          items={possibleStatuses}
          onSelect={handleStatusSelect}
          selectedText={statusLabel}
        />
      </Space>
    </Modal>
  );
}

export default memo(ReleaseStateModal);
