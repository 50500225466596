import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAppsQuery } from '@/features/api';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для работы со списком приложений.
 */
function useApplications() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isApplicationsLoading,
    data,
    error: getApplicationsError,
  } = useGetAppsQuery();

  const applications = data?.results || [];

  useEffect(() => {
    if (!getApplicationsError) {
      return;
    }

    notificationContext.showError(
      t('utils.error'),
      t('features.application.getApplicationsError'),
    );
  }, [getApplicationsError, notificationContext, t]);

  return {
    isApplicationsLoading,
    applications,
    getApplicationsError,
  };
}

export default useApplications;
