import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BooksIcon,
  DocumentAndGearIcon,
  HouseIcon,
  NoteIcon,
  PaintPaletteIcon,
  PlusIcon,
  SquareOnSquareIcon,
  TwoPersonIcon,
  WrenchAndScrewIcon,
} from '@aq_mobile/ui-kit/icons';

import { MenuItem } from '@/components';

function useMenuItems() {
  const { t } = useTranslation();

  const menuItems: Array<MenuItem> = useMemo(
    () => [
      {
        key: '/review',
        icon: <HouseIcon />,
        path: '/review',
        label: t('features.menuItems.review'),
        roles: ['moderator'],
      },
      {
        key: '/review-releases',
        icon: <SquareOnSquareIcon />,
        path: '/review-releases',
        label: t('features.menuItems.reviewReleases'),
        roles: ['moderator'],
      },
      {
        key: '/store-updates',
        icon: <WrenchAndScrewIcon />,
        path: '/store-updates',
        label: t('features.menuItems.storeUpdates'),
        subPath: '/store-updates/new',
        subIcon: <PlusIcon />,
        roles: ['admin'],
      },
      {
        key: '/users',
        icon: <TwoPersonIcon />,
        path: '/users',
        label: t('features.menuItems.users'),
        roles: ['admin'],
      },
      {
        key: '/apps',
        icon: <SquareOnSquareIcon />,
        path: '/apps',
        label: t('features.menuItems.apps'),
        roles: ['admin', 'developer'],
      },
      {
        key: '/categories',
        icon: <BooksIcon />,
        path: '/categories',
        label: t('features.menuItems.categories'),
        roles: ['admin'],
      },
    ],
    [t],
  );

  const appMenu: Array<MenuItem> = useMemo(
    () => [
      {
        key: '/apps/:id',
        icon: <PaintPaletteIcon />,
        path: '/apps/:id',
        label: t('features.menuItems.app'),
      },
      {
        key: '/apps/:id/releases',
        icon: <DocumentAndGearIcon />,
        path: '/apps/:id/releases',
        label: t('features.menuItems.appReleases'),
        roles: ['developer'],
      },
      {
        key: '/apps/:id/releases',
        icon: <DocumentAndGearIcon />,
        path: '/apps/:id/releases-admin',
        label: t('features.menuItems.appReleasesAdmin'),
        roles: ['admin'],
      },
      {
        key: '/apps/:id/release',
        icon: <DocumentAndGearIcon />,
        path: '/apps/:id/release',
        label: t('features.menuItems.appRelease'),
        roles: ['moderator'],
      },
      {
        key: '/apps/:id/history',
        icon: <NoteIcon />,
        path: '/apps/:id/history',
        label: t('features.menuItems.appHistory'),
      },
    ],
    [t],
  );

  return {
    menuItems,
    appMenu,
  };
}

export default useMenuItems;
