import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useApplication } from '@/features/application';
import { useAppDispatch } from '@/features/hooks';
import { rootActions } from '@/features/store';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Раздел для работы с приложением.
 */
export default function ApplicationRoot() {
  const { t } = useTranslation();
  const { id } = useParams();
  const hasId = Boolean(id) || id === '0';
  const { applicationData } = useApplication(id);
  const notificationContext = useContext(NotificationContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (!hasId) {
    notificationContext.showError(
      t('routes.ApplicationRoot.cannotOpen'),
      t('routes.ApplicationRoot.noApplicationID'),
    );
    navigate('/apps');
  }

  useEffect(() => {
    if (!applicationData) {
      return;
    }

    // Эта информация используется меню, для навигации
    dispatch(
      rootActions.application.set({
        id: applicationData.id,
        name: applicationData.name,
      }),
    );

    // При выходе из раздела сбрасываем текущее приложение
    return () => {
      dispatch(rootActions.application.clear());
    };
  }, [applicationData, dispatch]);

  return <Outlet />;
}
