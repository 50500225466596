// eslint-disable-next-line simple-import-sort/imports
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { ReleaseStatus } from '../release.types';

/**
 * Возвращает текстовое описание статуса
 */
function useReleaseStatusName(status?: ReleaseStatus): string {
  const { t } = useTranslation();

  switch (status) {
    case ReleaseStatus.approved:
      return t('features.release.statusApproved');
    case ReleaseStatus.created:
      return t('features.release.statusCreated');
    case ReleaseStatus.banned:
      return t('features.release.statusBanned');
    case ReleaseStatus.onReview:
      return t('features.release.statusOnReview');
    case ReleaseStatus.sentForReview:
      return t('features.release.statusSentForReview');
    case ReleaseStatus.published:
      return t('features.release.statusPublished');
    case ReleaseStatus.rejected:
      return t('features.release.statusRejected');
    default:
      return t('features.release.statusNoStatus');
  }
}

export default useReleaseStatusName;
