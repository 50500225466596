export type ApplicationState = {
  id: number | null;
  name: string | null;
};

export type ApplicationInfo = {
  id: number;
  app_id: string;
  categories: Array<number>;
  name: string;
};

/**
 * Имя настройки, в которой хранится колонка сортировки таблицы приложений
 */
export const APPLICATIONS_SORT_COLUMN_SETTING = 'applicationsSortColumn';
