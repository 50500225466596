import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';

import { ageRating } from '@/features/ageRating';

import { DescriptionProps, ReleaseForm } from './ReleaseNew.types';

/**
 * Раздел описания приложения
 */
function ReleaseNewDescription({ deviceGroups }: DescriptionProps) {
  const { t } = useTranslation();
  const groupOptions = useMemo(() => {
    if (!deviceGroups) {
      return [];
    }

    return deviceGroups.map((group) => ({
      label: group.name,
      value: group.id,
    }));
  }, [deviceGroups]);

  return (
    <>
      <Form.Item<ReleaseForm>
        label={t('routes.ReleaseNewDescription.description')}
        name={['description', 'description']}
        rules={[
          {
            required: true,
            message: t('routes.ReleaseNewDescription.descriptionRequired'),
          },
        ]}
      >
        <Input.TextArea autoSize={{ minRows: 2 }} />
      </Form.Item>
      <Form.Item<ReleaseForm>
        label={t('routes.ReleaseNewDescription.ageRating')}
        name={['description', 'rating']}
        rules={[
          {
            required: true,
            message: t('routes.ReleaseNewDescription.ageRatingRequired'),
          },
        ]}
      >
        <Select options={ageRating} />
      </Form.Item>
      <Form.Item<ReleaseForm>
        label={t('routes.ReleaseNewDescription.deviceGroups')}
        name={['description', 'device_group']}
      >
        <Select options={groupOptions} mode="multiple" />
      </Form.Item>
    </>
  );
}

export default ReleaseNewDescription;
